@font-face {
  font-family: "Thuner";
  src: url("../assets/fonts/Thuner.ttf") format("truetype");
}

:root {
  --gold: #FCB802;
  --black: #000000;
  --dark-gray: #111111;
  --light-gray: #333333;
}

.landing-page {
  background-color: var(--black);
  color: white;
  min-height: 100vh;
}

/* Hero Section */
.hero {
  position: relative;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
}

.hero-background {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: url("../assets/newHome/bg.webp");
  background-size: 100% 100%;
  background-repeat: no-repeat;
  z-index: 0;
}

.hero-content {
  position: relative;
  z-index: 1;
  text-align: center;
  padding: 0 2rem;
  max-width: 1200px;
}

.hero-title {
  font-family: "Thuner", sans-serif;
  font-size: 4vw;
  margin-bottom: 1vw;
  padding-top: 10vw;
  line-height: 1.1;
  text-transform: uppercase;
  position: relative;
  letter-spacing: 2px;
}

.hero-title span {
  color: var(--gold);
  display: block;
  text-shadow: 0 0 20px rgba(252, 184, 2, 0.3);
}

.hero-description {
  font-size: 1.5vw;
  margin-bottom: 2vw;
  max-width: 50vw;
  margin-left: auto;
  margin-right: auto;
  line-height: 1.6;
  font-family: "Roboto Condensed", sans-serif;
  
}

@media (max-width: 768px) {
  .hero-background {
    background: url("../assets/newHome/bg-mobile.webp");
    background-size: cover;
    background-repeat: no-repeat;
  }
  .hero-title{
    font-size: 6vw;
  }
  .hero-description{
    font-size: 3vw;
    max-width: 80vw;
  }
  .exploreButton{
    font-size: 3vw !important;
  }
}

.projects {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  background: linear-gradient(to bottom, var(--black), var(--dark-gray));
}

.projectBG {
  width: 25%;
  height: auto;
  min-height: 54.5vw;
  background-size: cover, contain;
  background-position: center, top;
  background-repeat: no-repeat, no-repeat;
  position: relative;
}

.cyberskiesProjBG {
  background-image: url("../assets/newHome/projs/logoLines.png"), url("../assets/newHome/projs/cyberskies.webp");
}

.cursedmansionProjBG {
  background-image: url("../assets/newHome/projs/logoLines.png"), url("../assets/newHome/projs/cursedmansion.webp");
}

.quantumbreachProjBG {
  background-image: url("../assets/newHome/projs/logoLines.png"), url("../assets/newHome/projs/quantumbreach.webp");
}

.cosmiclabsProjBG {
  background-image: url("../assets/newHome/projs/logoLines.png"), url("../assets/newHome/projs/cosmicgenesis.webp");
}

.learnMoreProjs {
  width: 8vw;
  position: absolute;
  bottom: 13vw;
  right: 3vw;
  cursor: pointer;
}

@media (max-width: 768px) {
  .projectBG {
    width: 50%;
    min-height: 105.5vw;
  }
  .learnMoreProjs {
    bottom: 25vw;
    width: 15vw;
    right: 6vw;
  }
}
/* IP Grid Section */
.ip-section {
  padding: 8rem 2rem;
  position: relative;
  background: linear-gradient(to bottom, var(--black), var(--dark-gray));
}

.ip-subtitle {
  font-size: 1.25rem;
  text-align: center;
  color: #94a3b8;
  max-width: 48rem;
  margin: 0 auto 4rem auto;
  font-family: "Roboto Condensed", sans-serif;
}
.ip-grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 2rem;
  max-width: 1400px;
  margin: 0 auto;
  align-items: stretch;
}

.ip-card {
  background: rgba(17, 17, 17, 0.7);
  backdrop-filter: blur(10px);
  border: 1px solid rgba(252, 184, 2, 0.1);
  border-radius: 1rem;
  padding: 1rem;
  transition: all 0.3s ease;
  position: relative;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  height: 550px;
  cursor: pointer;
}

.ip-card::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(45deg, transparent, rgba(252, 184, 2, 0.1));
  opacity: 0;
  transition: opacity 0.3s ease;
}

.ip-card:hover {
  transform: translateY(-10px);
  border-color: var(--gold);
}

.ip-card:hover::before {
  opacity: 1;
}

.ip-image {
  width: 100%;
  height: 250px;
  object-fit: cover;
  border-radius: 0.5rem;
  margin-bottom: 1.5rem;
  transition: transform 0.3s ease;
}

.ip-card:hover .ip-image {
  transform: scale(1.05);
}

.ip-title {
  font-family: "Thuner", sans-serif;
  font-size: 3rem;
  text-align: center;
  margin-bottom: 1rem;
  color: white;
}

.ip-title2 {
  font-family: "Thuner", sans-serif;
  font-size: 1.8rem;
  margin-bottom: 1rem;
  color: var(--gold);
  letter-spacing: 1px;
  min-height: 4.4rem;
  line-height: 1.2;
  display: flex;
  text-align: center;
  align-items: center;
}

.ip-description {
  font-size: 1.2rem;
  line-height: 1.6;
  color: #d1d5db;
  flex-grow: 1;
  overflow-y: hidden;
  padding-right: 0.5rem;
  font-family: "Roboto Condensed", sans-serif;

}

/* CTA Section */
.cta-section {
  padding: 8rem 2rem;
  background: linear-gradient(45deg, var(--black), var(--dark-gray));
  position: relative;
  text-align: center;
}

.cta-container {
  max-width: 1000px;
  margin: 0 auto;
}

.cta-title {
  font-family: "Thuner", sans-serif;
  font-size: 2.5rem;
  margin-bottom: 1.5rem;
  letter-spacing: 1px;
}

.cta-description {
  font-size: 1.25rem;
  color: #d1d5db;
  margin-bottom: 2rem;
  font-family: "Roboto Condensed", sans-serif;
}

.cta-buttons {
  display: flex;
  gap: 1rem;
  justify-content: center;
  flex-wrap: wrap;
}

.cta-button {
  padding: 1rem 3rem;
  font-size: 1.5vw;
  font-weight: bold;
  border: 1px solid var(--gold);
  background: transparent;
  color: var(--gold);
  border-radius: 0.5rem;
  cursor: pointer;
  transition: all 0.3s ease;
  position: relative;
  overflow: hidden;
  text-transform: uppercase;
  margin: 1rem;
}

.cta-button::before {
  content: '';
  position: absolute;
  top: 0;
  left: -100%;
  width: 100%;
  height: 100%;
  background: var(--gold);
  transition: all 0.3s ease;
  z-index: -1;
}

.cta-button:hover {
  color: #000;
  border: 1px solid #000
}
.socialButtons:hover{
  color: #fff;
  border: 1px solid #fff
}

.cta-button:hover::before {
  left: 0;
}

/* Responsive Design */
@media (max-width: 768px) {
  .hero-content {
    padding: 0 1rem;
  }
  
  .ip-section,
  .web3-section,
  .cta-section {
    padding: 4rem 1rem;
  }
  
  .web3-grid, .ip-grid {
    grid-template-columns: 1fr;
  }
}


/* web3 */
.web3-section {
  padding: 6rem 1.5rem;
  background: linear-gradient(to bottom, var(--dark-gray), var(--black));
  position: relative;
}

.web3-container {
  max-width: 1400px;
  margin: 0 auto;
}

.web3-title {
  font-family: "Thuner", sans-serif;
  font-size: 3rem;
  text-align: center;
  margin-bottom: 1rem;
  color: white;
}

.web3-subtitle {
  font-size: 1.25rem;
  text-align: center;
  color: #94a3b8;
  max-width: 48rem;
  margin: 0 auto 4rem auto;
  font-family: "Roboto Condensed", sans-serif;
}

.features-grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 2rem;
  align-items: stretch;
}

.feature-card {
  padding: 1.5rem;
  border-radius: 1rem;
  border: 1px solid rgba(75, 85, 99, 0.4);
  backdrop-filter: blur(10px);
  transition: all 0.3s ease;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.feature-card:hover {
  transform: translateY(-4px);
  border-color: var(--gold)
}

/* Feature-specific gradients */
.nft-feature {
  background: linear-gradient(135deg, rgba(234, 179, 8, 0.1), rgba(234, 179, 8, 0.02));
}

.token-feature {
  background: linear-gradient(135deg, rgba(59, 130, 246, 0.1), rgba(59, 130, 246, 0.02));
}

.game-feature {
  background: linear-gradient(135deg, rgba(34, 197, 94, 0.1), rgba(34, 197, 94, 0.02));
}

.dao-feature {
  background: linear-gradient(135deg, rgba(168, 85, 247, 0.1), rgba(168, 85, 247, 0.02));
}

.feature-header {
  display: flex;
  align-items: center;
  gap: 1rem;
  margin-bottom: 1rem;
}

.icon-container {
  padding: 0.75rem;
  border-radius: 0.75rem;
  background-color: rgba(31, 41, 55, 0.5);
}

/* Feature-specific icon colors */
.nft-feature .feature-icon {
  color: #facc15;
}

.token-feature .feature-icon {
  color: #60a5fa;
}

.game-feature .feature-icon {
  color: #4ade80;
}

.dao-feature .feature-icon {
  color: #c084fc;
}

.header-text {
  flex: 1;
}

.feature-card-title {
  font-family: "Thuner", sans-serif;
  font-size: 1.5rem;
  color: white;
  margin: 0;
}

.feature-subtitle {
  font-size: 0.875rem;
  color: #94a3b8;
  margin: 0;
  font-family: "Roboto Condensed", sans-serif;
}

.feature-description {
  color: #e2e8f0;
  margin-bottom: 1rem;
  line-height: 1.5;
  font-family: "Roboto Condensed", sans-serif;
}

.benefits-list {
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.benefit-item {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  color: #94a3b8;
  font-size: 1rem;
  font-family: "Roboto Condensed", sans-serif;
}

.benefit-bullet {
  width: 0.375rem;
  height: 0.375rem;
  border-radius: 50%;
  background-color: #4b5563;
  flex-shrink: 0;
}

/* Responsive adjustments */
@media (max-width: 1200px) {
  .features-grid {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (max-width: 768px) {
  .web3-section {
    padding: 4rem 1rem;
  }

  .features-grid {
    grid-template-columns: 1fr;
  }

  .web3-title {
    font-size: 2.5rem;
  }

  .feature-card {
    padding: 1.25rem;
  }
}