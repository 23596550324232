@font-face {
  font-family: 'Thuner'; /* Choose a unique name for your font */
  src: url('../../../assets/fonts/Thuner.ttf') format('truetype'); /* Adjust the path accordingly */
}
@font-face {
  font-family: 'AgencyR'; /* Choose a unique name for your font */
  src: url('../../../assets/fonts/AgencyR.ttf') format('truetype'); /* Adjust the path accordingly */
}
@font-face {
  font-family: 'AgencyB'; /* Choose a unique name for your font */
  src: url('../../../assets/fonts/AgencyB.ttf') format('truetype'); /* Adjust the path accordingly */
}
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800;900&family=Roboto+Condensed:ital,wght@0,100..900;1,100..900&display=swap');
 
body
{
    background: white;
}
.foundationHeader{
  position: absolute;
  top: 10vw;
  left: 17%;
  transform: translateX(-50%);
  width: 20vw; 
  height: auto; 
}
.line1Fo {
  position: absolute;
  top: 5vw;
  left: 41%;
  transform: translateX(-50%);
  width: 21vw; 
  height: auto; 
}
.line2F {
  position: absolute;
  top: 14vw;
  left: 11%;
  transform: translateX(-50%);
  width: 5vw; 
  height: auto; 
  z-index: 6;
}
.line3F {
  position: absolute;
  top: 46vw;
  right: 3%;
  width: 25vw; 
  height: auto; 
}
.scrollToNavF{
  position: absolute;
  top: 46vw;
  right: 29%;
  width: 5vw; 
  height: auto; 
}
.mouseF{
  position: absolute;
  top: 45.7vw;
  right: 34.5%;
  width: 1vw; 
  height: auto; 
}
.chimbaPinkF{
    position: absolute;
    top: 5vw;
    right: 0%;
    width: 35vw; 
    height: auto; 
    z-index: 4;
}
.pinkBlock{
    position: absolute;
    top: 25vw;
    width: 100%;
    right: 0%;
    z-index: 5;
}
.pinkTextBlock{
    position: absolute;
    top: 25vw;
    width: 18.5vw;
    left: 17%;
    z-index: 6;
}
.stayUpdated{
    position: absolute;
    top: 40vw;
    width: 14vw;
    left: 25%;
    z-index: 6;
    cursor: pointer;
    transition: all 0.25s ease-in-out;
}
.stayUpdated:hover{
    transform: scale(0.98);
}
.foundationPara{
    font-family: "Roboto Condensed", sans-serif;
    position: absolute;
    top: 28vw;
    left: 33.5%;
    z-index: 6;
    color: white;
    font-size: 1vw;
    line-height: 1.5;
}


/* SecondPage STARTS HERE*/
.chimbaLogoColor{
    position: absolute;
    top: 49vw;
    left: 38%;
    width: 36vw;
}
.line4F{
    position: absolute;
    top: 49.5vw;
    left: 13.265%;
    width: 0.5vw;
}
.line5F{
    position: absolute;
    top: 65vw;
    left: 8.75%;
    width: 6vw;
    z-index: 5;
}
.line6F{
    position: absolute;
    top: 48vw;
    right: 3%;
    width: 9vw;
    z-index: 5;
}
.lineAccent{
    position: absolute;
    top: 83.4vw;
    right: 11.5%; 
    width: 2vw;
    z-index: 6;
}
.firstHeaderF{
  position: absolute;
  top: 60.5vw;
  left: 6%; 
  width: 18vw;
}
.secondHeaderF{
  position: absolute;
  top: 82.4vw;
  right: 15%; 
  width: 18vw;
}
.firstHPF{
  font-family: "Roboto Condensed", sans-serif;
  position: absolute;
  top: 65vw;
  left: 15%;
  z-index: 6;
  color: black;
  font-weight: 300;
  font-size: 1vw;
  line-height: 1.5;
}
.secondHPF{
  font-family: "Roboto Condensed", sans-serif;
  position: absolute;
  top: 86vw;
  right: 15%;
  z-index: 6;
  color: black;
  font-weight: 300;
  text-align: right;
  font-size: 1vw;
  line-height: 1.5;
}
