@font-face {
  font-family: 'Thuner'; /* Choose a unique name for your font */
  src: url('../../../../assets/fonts/Thuner.ttf') format('truetype'); /* Adjust the path accordingly */
}
@font-face {
  font-family: 'AgencyR'; /* Choose a unique name for your font */
  src: url('../../../../assets/fonts/AgencyR.ttf') format('truetype'); /* Adjust the path accordingly */
}
@font-face {
  font-family: 'AgencyB'; /* Choose a unique name for your font */
  src: url('../../../../assets/fonts/AgencyB.ttf') format('truetype'); /* Adjust the path accordingly */
}
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800;900&family=Roboto+Condensed:ital,wght@0,100..900;1,100..900&display=swap');


.waifuBackground{
    position: absolute;
    top: 0vw;
    width: 100%;
}
.line1Waifu {
  position: absolute;
  top: 0vw;
  left: 50%;
  transform: translateX(-50%);
  width: 100%;
  height: auto; 
}

.scrollToNavWaifu{
    position: absolute;
    top: 52vw;
    right: 27.5%;
    width: 5vw; 
    height: auto; 
}
.mouseWaifu{
    position: absolute;
    top: 51.7vw;
    right: 33%;
    width: 1vw; 
    height: auto; 
}
.quantumHeader{
    position: absolute;
    top: 7vw;
    left: 6.5%;
    width: 30vw;
}
.quantumLogo{
    position: absolute;
    top: 4vw;
    right: 2vw;
    width: 12vw;
}
.playButtonQuantum{
    position: absolute;
    left: 47.5%;
    top: 24vw;
    width: 6vw;
    cursor: pointer;
    transition: all ease-in-out 0.2s;
}
.playButtonQuantum:hover{
    transform: scale(0.95);
}

.mainHeaderQBMain{
    font-family:'Thuner';
    font-size: 2.2vw;
    position: absolute;
    top: 5.2vw;
    left: 7%;
    z-index: 45;
    color: white;
  }
  .group-containerQBMain{
    position: absolute;
    top: 7.4vw;
    left: 7%;
    z-index: 45;
  }
  .rectangle-1QBMain{
    top: 3.4vw;
    width: 26vw;
    height: 0.1vw;
    background: white;
  }
  .rectangle-2QBMain {
    top: 3.4vw;
    width: 7vw;
    height: 0.1vw;
    background-color: #E7A302; /* You can use background-color for fill */
  }