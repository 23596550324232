@font-face {
    font-family: 'Thuner'; /* Choose a unique name for your font */
    src: url('../../../../assets/fonts/Thuner.ttf') format('truetype'); /* Adjust the path accordingly */
  }
  @font-face {
    font-family: 'AgencyR'; /* Choose a unique name for your font */
    src: url('../../../../assets/fonts/AgencyR.ttf') format('truetype'); /* Adjust the path accordingly */
  }
  @font-face {
    font-family: 'AgencyB'; /* Choose a unique name for your font */
    src: url('../../../../assets/fonts/AgencyB.ttf') format('truetype'); /* Adjust the path accordingly */
  }
  @import url('https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800;900&family=Roboto+Condensed:ital,wght@0,100..900;1,100..900&display=swap');
  
  
  .cosmicBackground{
      position: absolute;
      top: 0vw;
      width: 100%;
  }
  .line1Skies {
      position: absolute;
      top: 0vw;
      left: 50%;
      transform: translateX(-50%);
      width: 100%;
      height: auto; 
  }
  
  .mainHeaderCosmic{
    font-family:'Thuner';
    font-size: 2.6vw;
    position: absolute;
    top: 4.5vw;
    left: 6.7%;
    z-index: 45;
    color: white;
  }
  .group-containerCosmic {
    position: absolute;
    top: 7.4vw;
    left: 6.8%;
    z-index: 45;
  }
  .rectangle {
    position: absolute;
  }
  .rectangle-1Cosmic {
    top: 3.4vw;
    width: 22.6vw;
    height: 0.1vw;
    background: white;
  }
  .rectangle-2Cosmic {
    top: 3.4vw;
    width: 6vw;
    height: 0.1vw;
    background-color: #E7A302; /* You can use background-color for fill */
  }
  
  
  
  