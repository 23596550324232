.virusBG{
    z-index: 5;
    position: absolute;
    top: 166.5vw;
    width: 100vw;
}
.quantumLogo4{
    position: absolute;
    top: 170vw;
    right: 2vw;
    width: 12vw;
    z-index: 7;
}
.line4Quantum{
    position: absolute;
  top: 166.7vw;
  left: 50%;
  transform: translateX(-50%);
  width: 100%;
  height: auto; 
  z-index: 6;
}

.virusHeaderQuantum{
    position: absolute;
    top: 206.7vw;
    left: 6%;
    z-index: 7;
    width: 9vw;
}
.virusPara{
    z-index: 7;
    position: absolute;
    top: 213.5vw;
    left: 42%;
    color: rgba(255, 255, 255, 0.700);
    font-family: 'AgencyR';
    font-size: 1.25vw;
    font-weight: 100;
}
.virusTitleQuantum{
    font-family: 'Thuner';
    font-size: 2vw;
    color: white;
    z-index: 7;
    position: absolute;
    top: 215vw;
    left: 13%;
}

.virusHeaderQB{
    font-family:'Thuner';
    font-size: 2.4vw;
    position: absolute;
    top: 202vw;
    left: 5.7%;
    z-index: 45;
    color: white;
}
.group-containerVQB {
    position: absolute;
    top: 204.5vw;
    left: 5.8%;
    z-index: 45;
  }
  .rectangle {
    position: absolute;
  }
  .rectangle-1VQB {
    top: 3.4vw;
    width: 9.1vw;
    height: 0.1vw;
    background: white;
  }
  .rectangle-2VQB {
    top: 3.4vw;
    width: 3vw;
    height: 0.1vw;
    background-color: #E7A302; /* You can use background-color for fill */
  }