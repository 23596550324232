@font-face {
  font-family: 'Thuner'; /* Choose a unique name for your font */
  src: url('../../../assets/fonts/Thuner.ttf') format('truetype'); /* Adjust the path accordingly */
}
@font-face {
  font-family: 'AgencyR'; /* Choose a unique name for your font */
  src: url('../../../assets/fonts/AgencyR.ttf') format('truetype'); /* Adjust the path accordingly */
}
@font-face {
  font-family: 'AgencyB'; /* Choose a unique name for your font */
  src: url('../../../assets/fonts/AgencyB.ttf') format('truetype'); /* Adjust the path accordingly */
}
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800;900&family=Roboto+Condensed:ital,wght@0,100..900;1,100..900&display=swap');


.tokenBackground{
    position: absolute;
    top: 0vw;
    width: 100%;
}
.line1Token {
    position: absolute;
    top: 0vw;
    left: 38%;
    transform: translateX(-50%);
    width: 35vw; 
    height: auto; 
}
.line2Token {
    position: absolute;
    top: 11vw;
    left: 10.5%;
    transform: translateX(-50%);
    width: 5.4vw; 
    height: auto; 
}
.line3Token {
    position: absolute;
    top: 52.4vw;
    right: 3%;
    width: 25vw; 
    height: auto; 
}
.scrollToNavToken{
    position: absolute;
    top: 52.4vw;
    right: 29%;
    width: 5vw; 
    height: auto; 
}
.mouseToken{
    position: absolute;
    top: 52vw;
    right: 34.5%;
    width: 1vw; 
    height: auto; 
}
.tokenHeader {
  font-family:'Thuner';
  font-size: 2.4vw;
  position: absolute;
  top: 3.2vw;
  left: 6.6%;
  z-index: 45;
  color: white;
  background: url('../../../assets/token/shadow.png');
  background-size: 100% 100%;
  background-repeat: no-repeat;
  padding: 0.6vw;
}
.group-container {
  position: absolute;
  top: 6.2vw;
  left: 7.2%;
  z-index: 45;
}
.rectangle {
  position: absolute;
}
.rectangle-1 {
  top: 3.4vw;
  width: 10.4vw;
  height: 0.1vw;
  background: white;
}
.rectangle-2 {
  top: 3.4vw;
  width: 4vw;
  height: 0.1vw;
  background-color: #E7A302; /* You can use background-color for fill */
}
.tokenParaDiv{
    position: absolute;
    font-family:'Roboto Condensed';
    color: white;
    top: 15.5vw;
    left: 7%;
    width: 45vw;
    background: url('../../../assets/token/shadow.png'), url('../../../assets/token/shadow.png');
    background-size: 100% 100%;
    background-repeat: no-repeat; 
    padding: 5vw;
}
.tokenParaHeader{
    font-size: 1.5vw;
    letter-spacing: 0.2vw;
    font-family:'Thuner';
}
.tokenPara{
    font-size: 1vw;
    font-weight: 100;
}
.waitlist{
    position: absolute;
    top: 30vw;
    left: 12.2%;
    width: 17vw;
    cursor: pointer;
    transition: all ease-in-out 0.2s;
}
.waitlist:hover{
    transform: scale(0.95);
}
