.interior1Cursed{
    position: absolute;
    z-index: 2;
    top: 240vw;
    width: 100%;
}
.line9Cursed{
    position: absolute;
  top: 240vw;
  left: 50%;
  transform: translateX(-50%);
  width: 100%;
  z-index: 2;
  height: auto; 
}

.customizationHeaderCursed{
    position: absolute;
    z-index: 3;
    top: 250vw;
    left: 5.8%;
    width: 27vw;
}
.customParaDivCursed{
    z-index: 6;
    position: absolute;
    top: 276vw;
    left: 10.25%;
    color: rgba(255, 255, 255, 0.7);
    font-family: 'AgencyR';
    font-size: 1.25vw;
    font-weight: 100;
    width: 22vw;
}
.customTitleCursed{
    font-family: 'Thuner';
    font-size: 2vw;
    color: white;
    width: 27vw !important;
    margin-bottom: 0vw;
}

#interior1Icon{
    position: absolute;
    z-index: 3;
    top: 285vw;
    left: 50%;
    cursor: pointer;
    width: 6vw;
    transition: all ease-in-out 0.2s;
    border-radius: 99px;
}
#interior2Icon{
    position: absolute;
    z-index: 3;
    top: 285vw;
    left: 60%;
    cursor: pointer;
    width: 6vw;
    transition: all ease-in-out 0.2s;
    border-radius: 99px;
}
#interior3Icon{
    position: absolute;
    z-index: 3;
    top: 285vw;
    left: 70%;
    cursor: pointer;
    width: 6vw;
    transition: all ease-in-out 0.2s;
    border-radius: 99px;
}
#interior4Icon{
    position: absolute;
    z-index: 3;
    top: 285vw;
    left: 80%;
    cursor: pointer;
    width: 6vw;
    transition: all ease-in-out 0.2s;
    border-radius: 99px;
}
.selectedInteriorIcon{
    border: 2px solid rgb(196, 166, 1);
}

#interior1Icon:hover, #interior2Icon:hover, #interior3Icon:hover, #interior4Icon:hover{
    transform: scale(0.95);
}

.customizeHeaderCM{
    font-family:'Thuner';
    font-size: 2.4vw;
    position: absolute;
    top: 247vw;
    left: 6.7%;
    z-index: 45;
    color: white;
  }
  .group-containerCustomizeMain {
    position: absolute;
    top: 249.5vw;
    left: 6.8%;
    z-index: 45;
  }
  .rectangle {
    position: absolute;
  }
  .rectangle-1MainCustom {
    top: 3.4vw;
    width: 25vw;
    height: 0.1vw;
    background: white;
  }
  .rectangle-2MainCustom {
    top: 3.4vw;
    width: 6vw;
    height: 0.1vw;
    background-color: #E7A302; /* You can use background-color for fill */
  }