.background3Cosmic{
    position: absolute;
  width: 100%;
  top: 109vw;
  z-index: 3;
}
.lines2Cosmic{
    position: absolute;
    top: 109vw;
    left: 49.85%;
    transform: translateX(-50%);
    width: 100%;
    height: auto; 
    z-index: 6;
}
.mainHeaderCosmicChar {
    font-family: "Thuner";
    font-size: 2.4vw;
    position: absolute;
    top: 116vw;
    left: 6.1%;
    z-index: 45;
    color: white;
  }
  .group-containerCosmicChar {
    position: absolute;
    top: 118.5vw;
    left: 6.2%;
    z-index: 45;
  }
  .rectangle-1CosmicChar {
    top: 3.4vw;
    width: 28.3vw;
    height: 0.1vw;
    background: white;
  }
  .rectangle-2CosmicChar {
    top: 3.4vw;
    width: 8vw;
    height: 0.1vw;
    background-color: #e7a302; /* You can use background-color for fill */
  }
  
  .characterParaDivDucks{
    z-index: 6;
    position: absolute;
    top: 143.8vw;
    left: 10%;
    color: rgb(182, 182, 182);
    font-family: 'AgencyR';
    font-size: 1.25vw;
    font-weight: 100;
  }
  .whiteTitle{
    color: white !important;
  }