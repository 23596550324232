.background4Cursed{
    position: absolute;
    top: 182.2vw;
    width: 100%;
    z-index: 2;
}
.line7Cursed{
    position: absolute;
  top: 179vw;
  left: 50%;
  transform: translateX(-50%);
  width: 100%;
  z-index: 2;
  height: auto; 
}

.nftHeaderCursed{
    position: absolute;
    z-index: 3;
    left: 6%;
    top: 187.5vw;
    width: 6.5vw;
}
.separator4{
    position: absolute;
    width: 100%;
    top: 232.5vw;
    z-index: 3;
}
.nftHammerCursed{
    position: absolute;
    z-index: 3;
    left: 15%;
    top: 195vw;
    width: 16vw;
}
.nftPlaceholderCursed{
    position: absolute;
    z-index: 3;
    right: 4%;
    top: 189vw;
    width: 62vw;
}
.nftTitleCursed{
    position: absolute;
    z-index: 3;
    top: 223.5vw;
    left: 15%;
    color: rgb(182, 182, 182);
    font-family: 'Thuner';
    font-size: 2vw;
}
.nftParaCursed{
    position: absolute;
    z-index: 3;
    top: 222.5vw;
    left: 37%;
    color: rgb(182, 182, 182);
    font-family: 'AgencyR';
    font-size: 1.25vw;
    font-weight: 100;
}

.nftHeaderCB{
    font-family:'Thuner';
    font-size: 2.4vw;
    position: absolute;
    left: 5%;
    top: 184vw;
    z-index: 45;
    color: white;
}
.group-containerNCB {
    position: absolute;
    left: 5%;
    top: 186.5vw;
    z-index: 45;
  }
  .rectangle {
    position: absolute;
  }
  .rectangle-1NCB {
    top: 3.4vw;
    width: 8.3vw;
    height: 0.1vw;
    background: white;
  }
  .rectangle-2NCB {
    top: 3.4vw;
    width: 3vw;
    height: 0.1vw;
    background-color: #E7A302; /* You can use background-color for fill */
  }