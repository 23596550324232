@font-face {
  font-family: 'OldLondon'; /* Choose a unique name for your font */
  src: url('../../../../assets/cursedMansion/OldLondon.ttf') format('truetype'); /* Adjust the path accordingly */
}
@font-face {
  font-family: 'Deutsch'; /* Choose a unique name for your font */
  src: url('../../../../assets/cursedMansion/Deutsch.ttf') format('truetype'); /* Adjust the path accordingly */
}

.background3Cursed{
  position: absolute;
  top: 123.8vw;
  width: 100%;
  z-index: 3;
}
.characterBorderCursed{
  position: absolute;
  top: 125.8vw;
  left: 33.3%;
  z-index: 3;
  width: 33vw;
}
.detectiveCursed{
  position: absolute;
  top: 134vw;
  left: 39%;
  z-index: 3;
  width: 20vw;
}
.detectiveTitle{
  z-index: 3;
  position: absolute;
  top: 130.4vw;
  left: 43%;
  font-size: 1.25vw;
  color: #F7D9D3;
  font-family: 'Deutsch';
  text-align: center;
  width: 16vw;
}
.line5Cursed{
  position: absolute;
  top: 123vw;
  left: 50%;
  transform: translateX(-50%);
  width: 100%;
  z-index: 3;
  height: auto; 
}
.line6Cursed{
  position: absolute;
  top: 136vw;
  left: 6.8%;
  z-index: 3;
  width: 50vw;
}
.characterHeaderCursed{
  position: absolute;
  top: 132vw;
  left: 6.3%;
  z-index: 3;
  width: 21vw;
}
.seperator3{
  position: absolute;
  width: 100%;
  top: 159vw;
  z-index: 3;
}
.characterParaDivCursed{
  z-index: 6;
  position: absolute;
  top: 146vw;
  left: 11%;
  color: rgb(182, 182, 182);
  font-family: 'AgencyR';
  font-size: 1.25vw;
  font-weight: 100;
}
.leftArrowCursed{
  z-index: 3;
  position: absolute;
  left: 34%;
  top: 143vw;
  width: 3vw;
  cursor: pointer;
  transition: 0.2s all ease-in-out;
  -webkit-animation: pulsate-fwd 1s ease-in-out infinite both;
	animation: pulsate-fwd 1s ease-in-out infinite both;
}
.rightArrowCursed{
  z-index: 3;
  position: absolute;
  right: 34%;
  top: 143vw;
  width: 3vw;
  cursor: pointer;
  transition: 0.2s all ease-in-out;
  -webkit-animation: pulsate-fwd 1s ease-in-out infinite both;
	animation: pulsate-fwd 1s ease-in-out infinite both;
}
.rightArrowCursed:hover, .leftArrowCursed:hover{
  transform: scale(0.95);
}

.loreDivCursed{
  z-index: 3;
  position: absolute;
  right: 9%;
  top: 142vw;
  color: #A3948C;
  background: url('../../../../assets/cursedMansion/loreBackground.png');
  background-repeat: no-repeat;
  background-size: 100% 100%;
  font-family: 'OldLondon';
  width: 22vw;
  height: 18vw;
}
.loreTitleCursed{
  
  font-family: 'OldLondon';
  font-size: 2vw;
  
}
.loreParaCursed{
  position: absolute;
  left: 12%;
  top: 3vw;
  text-align: center;
  font-size: 1vw;
  width: 17vw;
}

.charHeaderCM{
  font-family:'Thuner';
  font-size: 2.4vw;
  position: absolute;
  top: 128.5vw;
  left: 6.7%;
  z-index: 45;
  color: white;
}
.group-containerCharMain {
  position: absolute;
  top: 131vw;
  left: 6.8%;
  z-index: 45;
}
.rectangle {
  position: absolute;
}
.rectangle-1MainChar {
  top: 3.4vw;
  width: 20.7vw;
  height: 0.1vw;
  background: white;
}
.rectangle-2MainChar {
  top: 3.4vw;
  width: 5vw;
  height: 0.1vw;
  background-color: #E7A302; /* You can use background-color for fill */
}

@-webkit-keyframes pulsate-fwd {
  0% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
  50% {
    -webkit-transform: scale(1.1);
            transform: scale(1.1);
  }
  100% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
}
@keyframes pulsate-fwd {
  0% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
  50% {
    -webkit-transform: scale(1.1);
            transform: scale(1.1);
  }
  100% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
}
