#backgroundPass {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 60%;
    z-index: 10;
    object-fit: fill;
    width: 100vw;
    height: 100%;
    transition: all ease-in-out 2s;
    pointer-events: none;
}
#clickAndHoldText{
    position: absolute;
    top: 12.5vw;
    left: 7.2%;
    z-index: 45;
    width: 23.9vw;
    color: black;
    display: none;
    font-size: 1vw;
    background-color: rgba(255, 255, 255, 0.4);
}
#tvVisiblity{
    display: none;
}
#tvBox{
    z-index: 46;
    height: 10vw;
    width: 16vw;
    position: absolute;
    left: 50vw;
    top: 37%;
    transition: all ease-in-out 2s;
    cursor: pointer;
}
#clickHoldTv{
    display: none;
    z-index: 45;
    width: 5vw;
    position: absolute;
    left: 54vw;
    top: 42%;
    cursor: pointer;
    filter: brightness(1);
}
#tvBox:hover + #clickHoldTv {
    display: block;
}
#accessAcademyDiv{
    display: none;
}
.accessAcademyPara{
    position: absolute;
    left: 33vw;
    top: 27%;
    z-index: 46;
    color: white;
    font-family: 'Roboto Condensed';
    letter-spacing: 1px;
    font-size: 1vw;
    width: 21vw;
    padding: 30px;
    text-wrap: wrap;
    border: 1px solid white;
    border-radius: 25px;
    background: url('../../../assets/pass/textBackground.png');
    background-size: 100% 100%;
    background-repeat: no-repeat;
}
.accessAcademyTitle{
    position: absolute;
    left: 34vw;
    top: 25%;
    z-index: 47;
    width: 20vw;
}
.accessAcademyLine{
    position: absolute;
    left: 53.5vw;
    top: 38%;
    z-index: 46;
    width: 8vw;
}
.accessAcademyPH{
    position: absolute;
    right: 3vw;
    top: 15%;
    z-index: 46;
    width: 23vw;
}
.accessAcademyPH2{
    position: absolute;
    right: 3vw;
    top: 55%;
    z-index: 46;
    width: 23vw;
}
.accessAcademyPHTitle{
    position: absolute;
    right: 13.5vw;
    top: 43%;
    z-index: 46;
    font-size: 1.25vw;
    color: white;
    background: url("../../../assets/pass/placeholderTitle.png");
    background-size: 100% 100%;
    background-repeat: no-repeat;
    padding-left: 30px;
    padding-right: 30px;
    padding-top: 5px;
    padding-bottom: 5px;
}
#attention{
    z-index: 44;
    display: none;
    position: absolute;
    left: 53vw;
    top: 38%;
}


#chimbaTokenDiv{
    display: none;
}
#chestVisibility{
    display: none;
}
#chestBox{
    z-index: 46;
    height: 8vw;
    width: 12vw;
    position: absolute;
    left: 72vw;
    top: 50%;
    transition: all ease-in-out 2s;
    cursor: pointer;
}
#clickHoldChest{
    display: none;
    z-index: 45;
    width: 5vw;
    position: absolute;
    left: 75vw;
    top: 52%;
    cursor: pointer;
    filter: brightness(1);
}
#chestBox:hover + #clickHoldChest {
    display: block;
}
#attentionChest{
    z-index: 44;
    display: none;
    position: absolute;
    left: 74vw;
    top: 46%;
}
.chimbaToken{
    z-index: 46;
    position: absolute;
    left: 50.5vw;
    top: 29%;
    width: 9vw;
}
.chimbaTokenLine{
    position: absolute;
    left: 47vw;
    top: 48%;
    z-index: 46;
    width: 25vw;
}


#nftVisibility{
    display: none;
}
#nftBox{
    z-index: 46;
    height: 11vw;
    width: 10vw;
    position: absolute;
    left: 56vw;
    top: 15%;
    transition: all ease-in-out 2s;
    cursor: pointer;
}
#clickHoldNft{
    display: none;
    z-index: 45;
    width: 5vw;
    position: absolute;
    left: 55vw;
    top: 20%;
    cursor: pointer;
    filter: brightness(1);
}
#nftBox:hover + #clickHoldNft {
    display: block;
}
.nftLine{
    position: absolute;
    left: 53.5vw;
    top: 34%;
    z-index: 46;
    width: 8vw;
}
#attentionNft{
    z-index: 44;
    display: none;
    position: absolute;
    left: 54vw;
    top: 15%;
}
#nftDiv{
    display: none;
}



#exclusiveEventsVisibility{
    display: none;
}
#exclusiveEventsBox{
    z-index: 46;
    height: 8vw;
    width: 5vw;
    position: absolute;
    left: 64vw;
    top: 78%;
    transition: all ease-in-out 2s;
    cursor: pointer;
}
#clickHoldExclusiveEvents{
    display: none;
    z-index: 45;
    width: 5vw;
    position: absolute;
    left: 63.5vw;
    top: 79%;
    cursor: pointer;
    filter: brightness(1);
}
#exclusiveEventsBox:hover + #clickHoldExclusiveEvents {
    display: block;
}
#attentionExclusiveEvents{
    z-index: 44;
    display: none;
    position: absolute;
    left: 62.5vw;
    top: 74%;
}
#exclusiveEventsDiv{
    display: none;
}
.exclusiveEventsLine{
    position: absolute;
    left: 46.5vw;
    top: 45.5%;
    z-index: 46;
    width: 21vw;
}




#earlyVisibility{
    display: none;
}
#earlyBox{
    z-index: 47;
    height: 5vw;
    width: 8vw;
    position: absolute;
    left: 50vw;
    top: 60%;
    transition: all ease-in-out 2s;
    cursor: pointer;
}
#clickHoldEarly{
    display: none;
    z-index: 45;
    width: 5vw;
    position: absolute;
    left: 51vw;
    top: 60%;
    cursor: pointer;
    filter: brightness(1);
}
#earlyBox:hover + #clickHoldEarly {
    display: block;
}
#attentionEarly{
    z-index: 44;
    display: none;
    position: absolute;
    left: 50vw;
    top: 55%;
}
#earlyDiv{
    display: none;
}
.earlyLine{
    position: absolute;
    left: 53.5vw;
    top: 35%;
    z-index: 46;
    width: 3vw;
}


#exclusiveVisibility{
    display: none;
}
#exclusiveBox{
    z-index: 47;
    height: 5vw;
    width: 8vw;
    position: absolute;
    left: 28vw;
    top: 60%;
    transition: all ease-in-out 2s;
    cursor: pointer;
}
#clickHoldExclusive{
    display: none;
    z-index: 45;
    width: 5vw;
    position: absolute;
    left: 30vw;
    top: 57%;
    cursor: pointer;
    filter: brightness(1);
}
#exclusiveBox:hover + #clickHoldExclusive {
    display: block;
}
#attentionExclusive{
    z-index: 44;
    display: none;
    position: absolute;
    left: 29vw;
    top: 52%;
}
#exclusiveDiv{
    display: none;
}
.exclusiveLine{
    position: absolute;
    left: 36.5vw;
    top: 48%;
    z-index: 46;
    width: 0.6vw;
}


#behindVisibility{
    display: none;
}
#behindBox{
    z-index: 47;
    height: 5vw;
    width: 8vw;
    position: absolute;
    left: 39vw;
    top: 52%;
    transition: all ease-in-out 2s;
    cursor: pointer;
}
#clickHoldBehind{
    display: none;
    z-index: 45;
    width: 5vw;
    position: absolute;
    left: 40.5vw;
    top: 49%;
    cursor: pointer;
    filter: brightness(1);
}
#behindBox:hover + #clickHoldBehind {
    display: block;
}
#attentionBehind{
    z-index: 44;
    display: none;
    position: absolute;
    left: 39.5vw;
    top: 44%;
}
#behindDiv{
    display: none;
}
.behindLine{
    position: absolute;
    left: 35vw;
    top: 48%;
    z-index: 46;
    width: 5vw;
}

.behindScenes{
    position: absolute;
    right: 3vw;
    top: 10%;
    z-index: 46;
    width: 19vw;
}
.behindScenes2{
    position: absolute;
    right: 3vw;
    top: 40%;
    z-index: 46;
    width: 19vw;
}
.behindScenes3{
    position: absolute;
    right: 3vw;
    top: 70%;
    z-index: 46;
    width: 19vw;
}