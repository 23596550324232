.tokenBackground2{
  position: absolute;
  top: 54.3vw;
  width: 100%;
}
.line4Token{
  position: absolute;
  left: 12.9%;
  top: 54.3vw;
  width: 0.6vw;
}
.line5Token{
  position: absolute;
  left: 7.65%;
  top: 66vw;
  width: 43vw;
}
.line6Token{
  position: absolute;
  right: 3%;
  top: 54.4vw;
  width: 12vw;
}
.playButtonToken{
  position: absolute;
  left: 46%;
  top: 74.5vw;
  cursor: pointer;
  width: 5vw;
  border-radius: 99px;
  border: solid 2px #FBB712;
}
.tokenHeader2 {
  font-family:'Thuner';
  font-size: 2.4vw;
  position: absolute;
  top: 58vw;
  left: 7.4%;
  z-index: 45;
  color: white;
  background: url('../../../assets/token/shadow.png');
  background-size: 100% 100%;
  background-repeat: no-repeat;
  padding: 0.6vw;
}
.group-container2 {
  position: absolute;
  top: 61vw;
  left: 8.05%;
  z-index: 45;
}
.chimbaToken2{
  position: absolute;
  top: 65vw;
  right: 19%;
  width: 21vw;
}
.coinLine{
  position: absolute;
  top: 63.5vw;
  right: 22.5%;
  width: 24vw;
}
.coinLine2{
  position: absolute;
  top: 64.4vw;
  right: 24%;
  width: 21.3vw;
}
.chimbaLogo{
  position: absolute;
  top: 84vw;
  left: 27%;
  width: 46.2vw;
}
.tokenParaDiv2{
  position: absolute;
  font-family:'Roboto Condensed';
  color: white;
  top: 68.5vw;
  left: 7%;
  background: url('../../../assets/token/shadow.png');
  background-size: 100% 100%;
  background-repeat: no-repeat; 
  padding: 5vw;
}
.token1{
  position: absolute;
  top: 90vw;
  left: 37%;
  width: 14vw;
  cursor: pointer;
  z-index: 46;
}
.token2{
  position: absolute;
  top: 90vw;
  left: 52%;
  width: 14vw;
  cursor: pointer;
  z-index: 46;
}
.token3{
  position: absolute;
  top: 90vw;
  left: 67%;
  width: 14vw;
  cursor: pointer;
  z-index: 46;
}
.token4{
  position: absolute;
  top: 90vw;
  left: 82%;
  width: 14vw;
  cursor: pointer;
  z-index: 46;
}
.bottomTokenText{
  position: absolute;
  right: 3.5%;
  top: 100vw;
  font-family:'Roboto Condensed';
  color: white;
  font-size: 1vw;
  text-align: right;
  font-weight: 100;
  width: 25vw;
}
.selectedToken{
  border: 2px solid gold;
}
