@font-face {
  font-family: "Thuner"; /* Choose a unique name for your font */
  src: url("../../../assets/fonts/Thuner.ttf") format("truetype"); /* Adjust the path accordingly */
}
@font-face {
  font-family: "AgencyR"; /* Choose a unique name for your font */
  src: url("../../../assets/fonts/AgencyR.ttf") format("truetype"); /* Adjust the path accordingly */
}
@font-face {
  font-family: "AgencyB"; /* Choose a unique name for your font */
  src: url("../../../assets/fonts/AgencyB.ttf") format("truetype"); /* Adjust the path accordingly */
}
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800;900&family=Roboto+Condensed:ital,wght@0,100..900;1,100..900&display=swap");

body {
  background: white;
}
.labsHeader {
  position: absolute;
  top: 10vw;
  left: 11.5%;
  transform: translateX(-50%);
  width: 8vw;
  height: auto;
}
.belowHeader {
  position: absolute;
  top: 12.2vw;
  left: 11.5%;
  transform: translateX(-50%);
  width: 8vw;
  height: auto;
}
.line1F {
  position: absolute;
  top: 5vw;
  left: 35%;
  transform: translateX(-50%);
  width: 22vw;
  height: auto;
}
.line2F {
  position: absolute;
  top: 14vw;
  left: 11%;
  transform: translateX(-50%);
  width: 5vw;
  height: auto;
  z-index: 6;
}
.line3F {
  position: absolute;
  top: 46vw;
  right: 3%;
  width: 25vw;
  height: auto;
}
.scrollToNavF {
  position: absolute;
  top: 46vw;
  right: 29%;
  width: 5vw;
  height: auto;
}
.mouseF {
  position: absolute;
  top: 45.7vw;
  right: 34.5%;
  width: 1vw;
  height: auto;
}
.chimbaPink {
  position: absolute;
  top: 5vw;
  right: 0%;
  width: 35vw;
  height: auto;
  z-index: 4;
}
.pinkBlock {
  position: absolute;
  top: 25vw;
  width: 100%;
  right: 0%;
  z-index: 5;
}
.pinkTextBlock {
  position: absolute;
  top: 25vw;
  width: 18.5vw;
  left: 17%;
  z-index: 6;
}
.stayUpdated {
  position: absolute;
  top: 40vw;
  width: 14vw;
  left: 25%;
  z-index: 6;
  cursor: pointer;
  transition: all 0.25s ease-in-out;
}
.stayUpdated:hover {
  transform: scale(0.98);
}
.foundationPara {
  font-family: "Roboto Condensed", sans-serif;
  position: absolute;
  top: 28vw;
  left: 33.5%;
  z-index: 6;
  color: white;
  font-size: 1vw;
  line-height: 1.5;
}

/* SecondPage STARTS HERE*/
.chimbaLogoColor {
  position: absolute;
  top: 49vw;
  left: 38%;
  width: 36vw;
}
.line4F {
  position: absolute;
  top: 49.5vw;
  left: 13.265%;
  width: 0.5vw;
}
.line5F {
  position: absolute;
  top: 65vw;
  left: 8.75%;
  width: 6vw;
  z-index: 5;
}
.line6F {
  position: absolute;
  top: 48vw;
  right: 3%;
  width: 9vw;
  z-index: 5;
}
.lineAccent {
  position: absolute;
  top: 83.4vw;
  right: 11.5%;
  width: 2vw;
  z-index: 6;
}
.firstHeaderL {
  position: absolute;
  top: 61.5vw;
  left: 8%;
  width: 10vw;
}
.secondHeaderL {
  position: absolute;
  top: 82.7vw;
  right: 15%;
  width: 10vw;
}
.firstHPF {
  font-family: "Roboto Condensed", sans-serif;
  position: absolute;
  top: 65vw;
  left: 15%;
  z-index: 6;
  color: black;
  font-weight: 300;
  font-size: 1vw;
  line-height: 1.5;
}
.secondHPF {
  font-family: "Roboto Condensed", sans-serif;
  position: absolute;
  top: 86vw;
  right: 15%;
  z-index: 6;
  color: black;
  font-weight: 300;
  text-align: right;
  font-size: 1vw;
  line-height: 1.5;
}

.labsHeader1 {
  font-family: "Thuner";
  font-size: 2.4vw;
  position: absolute;
  top: 57.5vw;
  left: 5%;
  z-index: 45;
  color: black;
}
.group-containerLH1 {
  position: absolute;
  top: 60vw;
  left: 5%;
  z-index: 45;
}
.rectangle {
  position: absolute;
}
.rectangle-1labs {
  top: 3.4vw;
  width: 18.7vw;
  height: 0.1vw;
  background: black;
}
.rectangle-2labs {
  top: 3.4vw;
  width: 6vw;
  height: 0.1vw;
  background-color: #e7a302; /* You can use background-color for fill */
}


.labsHeader2 {
  font-family: "Thuner";
  font-size: 2.4vw;
  position: absolute;
  top: 79.5vw;
  right: 15%;
  z-index: 45;
  color: black;
}
.group-containerLH2 {
  position: absolute;
  top: 82vw;
  right: 31.8%;
  z-index: 45;
}
.rectangle {
  position: absolute;
}
.rectangle-1labs2 {
  top: 3.4vw;
  width: 16.6vw;
  height: 0.1vw;
  background: black;
}
.rectangle-2labs2 {
  top: 3.4vw;
  width: 6vw;
  height: 0.1vw;
  background-color: #e7a302; /* You can use background-color for fill */
}