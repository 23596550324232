.bgCosmic{
    position: absolute;
    width: 100%;
    top: 54.3vw;
}
.line4Skies{
  position: absolute;
  top: 54.3vw;
  left: 50%;
  transform: translateX(-50%);
  width: 100%;
  height: auto; 
}

.historyParaDivCosmic{
    z-index: 6;
    position: absolute;
    top: 85.5vw;
    left: 7%;
    color: rgba(255, 255, 255, 0.700);
    font-family: 'AgencyR';
    font-size: 1.25vw;
    font-weight: 100;
    background: url('../../../../assets/token/shadow.png'), url('../../../../assets/token/shadow.png');
    background-size: 100% 100%;
    background-repeat: no-repeat; 
    padding: 5vw;
}
.historyTitleSkies{
    font-family: 'Thuner';
    font-size: 2vw;
    color: white;
}

.storyHeaderCS{
    font-family:'Thuner';
    font-size: 2.4vw;
    position: absolute;
    top: 61vw;
    left: 8.2%;
    z-index: 45;
    color: white;
}
.group-containerCS {
    position: absolute;
    top: 63.5vw;
    left: 8.2%;
    z-index: 45;
  }
  .rectangle {
    position: absolute;
  }
  .rectangle-1 {
    top: 3.4vw;
    width: 10.4vw;
    height: 0.1vw;
    background: white;
  }
  .rectangle-2 {
    top: 3.4vw;
    width: 4vw;
    height: 0.1vw;
    background-color: #E7A302; /* You can use background-color for fill */
  }
