/* MainInfo.css */
@font-face {
  font-family: 'Thuner'; /* Choose a unique name for your font */
  src: url('../../../assets/fonts/Thuner.ttf') format('truetype'); /* Adjust the path accordingly */
}
@font-face {
  font-family: 'AgencyR'; /* Choose a unique name for your font */
  src: url('../../../assets/fonts/AgencyR.ttf') format('truetype'); /* Adjust the path accordingly */
}
@font-face {
  font-family: 'AgencyB'; /* Choose a unique name for your font */
  src: url('../../../assets/fonts/AgencyB.ttf') format('truetype'); /* Adjust the path accordingly */
}
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800;900&family=Roboto+Condensed:ital,wght@0,100..900;1,100..900&display=swap');

.container {
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 101;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.hidden {
  display: none;
}

.ClickLine {
  width: 22vw;
  margin-top: 2%;
  margin-bottom: 5px;
  position: relative;
}

.link-text {
  text-align: center;
  font-size: 13px;
  width: 350px;
  font-weight: 700;
  color: black;
  margin-top: 5px;
  position: relative;
}

.link-text.active {
  color: #FCB802;
}

.small-screen {
  top: 10%;
}

.BottomLine {
  position: absolute;
  left: 50%;
  bottom: 0%; /* Adjust the value based on your design */
  width: 33vw;
}

.BottomRightLine {
  position: absolute;
  right: 2%;
  bottom: 0%; /* Adjust the value based on your design */
  width: 3.3vw;
}

.X {
  position: absolute;
  bottom: 0%;
  right: 14%;
  width: 1.78vw;
  transition: all 0.1s ease-in-out;
}

.Discord {
  position: absolute;
  bottom: 0%;
  right: 12%;
  width: 1.78vw;
  transition: all 0.1s ease-in-out;
}

.YT {
  position: absolute;
  bottom: 0%;
  right: 10%;
  width: 1.78vw;
  transition: all 0.1s ease-in-out;
}

.TIKTOK {
  position: absolute;
  bottom: 0%;
  right: 8%;
  width: 1.78vw;
  transition: all 0.1s ease-in-out;
}

.IG {
  position: absolute;
  bottom: 0%;
  right: 6%;
  width: 1.78vw;
  transition: all 0.1s ease-in-out;
}

.X:hover,.Discord:hover,.YT:hover,.TIKTOK:hover,.IG:hover{
  cursor: pointer;
  bottom: 0.5%;
}

.chimbaLogoGreyHome{
  position: absolute;
  bottom: 0;
  left: 6%;
  width: 28rem;
  z-index: 1;
  pointer-events: none;
}
.chimbaLogoGreyHomeMobile{
  position: absolute;
  bottom: 0;
  left: 5%;
  width: 12rem;
  z-index: 1;
  pointer-events: none;
}

.leftSideLine{
  position: absolute;
  left: 0;
  top: 50%;
  width: 10vw;
  pointer-events: none;
}
.rightSideLine{
  position: absolute;
  right: 0;
  top: 35%;
  width: 10vw;
  pointer-events: none;
}
.rotateIcon{
  position: absolute;
  top: 77%;
  width: 15rem;
  left: 50%;
  transform: translateX(-50%);
  pointer-events: none;
}
.rotateIconMobile{
  position: absolute;
  top: 73%;
  width: 10rem;
  left: 50%;
  transform: translateX(-50%);
  pointer-events: none;
}
