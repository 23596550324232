@font-face {
  font-family: "Thuner"; /* Choose a unique name for your font */
  src: url("../../../../assets/fonts/Thuner.ttf") format("truetype"); /* Adjust the path accordingly */
}
@font-face {
  font-family: "AgencyR"; /* Choose a unique name for your font */
  src: url("../../../../assets/fonts/AgencyR.ttf") format("truetype"); /* Adjust the path accordingly */
}
@font-face {
  font-family: "AgencyB"; /* Choose a unique name for your font */
  src: url("../../../../assets/fonts/AgencyB.ttf") format("truetype"); /* Adjust the path accordingly */
}
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800;900&family=Roboto+Condensed:ital,wght@0,100..900;1,100..900&display=swap");

.racingBackground{
  position: absolute;
  top: 0vw;
  width: 100%;
}
.line1Skies {
  position: absolute;
  top: 0vw;
  left: 50%;
  transform: translateX(-50%);
  width: 100%;
  height: auto;
}

.line3Racing {
  position: absolute;
  top: 53vw;
  right: 1.75%;
  width: 25vw;
  height: auto;
}
.scrollToNavRacing {
  position: absolute;
  top: 53vw;
  right: 27.5%;
  width: 5vw;
  height: auto;
}
.mouseRacing {
  position: absolute;
  top: 52.7vw;
  right: 33%;
  width: 1vw;
  height: auto;
}
.comingSoonRacing {
  position: absolute;
  top: 0vw;
  width: 100%;
}

.mainHeaderRacing {
  font-family: "Thuner";
  font-size: 2.6vw;
  position: absolute;
  top: 4.5vw;
  left: 4.5%;
  z-index: 45;
  color: white;
}
.group-containerRacing {
  position: absolute;
  top: 7.3vw;
  left: 4.6%;
  z-index: 45;
}
.rectangle-1Racing {
  top: 3.4vw;
  width: 26vw;
  height: 0.1vw;
  background: white;
}
.rectangle-2Racing {
  top: 3.4vw;
  width: 5vw;
  height: 0.1vw;
  background-color: #e7a302; /* You can use background-color for fill */
}
