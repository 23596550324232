/* MainInfo.css */
@font-face {
  font-family: 'Thuner'; /* Choose a unique name for your font */
  src: url('../assets/fonts/Thuner.ttf') format('truetype'); /* Adjust the path accordingly */
}
@font-face {
  font-family: 'AgencyR'; /* Choose a unique name for your font */
  src: url('../assets/fonts/AgencyR.ttf') format('truetype'); /* Adjust the path accordingly */
}
@font-face {
  font-family: 'AgencyB'; /* Choose a unique name for your font */
  src: url('../assets/fonts/AgencyB.ttf') format('truetype'); /* Adjust the path accordingly */
}
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800;900&family=Roboto+Condensed:ital,wght@0,100..900;1,100..900&display=swap');


.footer {
  background-color: #f8b502;
  color: #fff;
  padding: 20px;
  height: 18vw;
  position: absolute;
  bottom: 0;
  width: 100%;
  z-index: 10;
}
.cyberLogoFooter{
  position: absolute;
  bottom: 0;
  left: 0;
  width: 20vw;
}
.line1Footer{
  position: absolute;
  width: 4vw;
  top: 0;
  left: 13%;
}
.footerHeader{
  position: absolute;
  width: 15.7vw;
  top: 4.3vw;
  left: 18%
}
.footerMenuItem{
  font-family: "AgencyB";
  position: absolute;
  color: black;
  font-size: 1.5vw;
  cursor: pointer;
  top: 5vw;
}
.footerMenuExtra{
  font-family: "AgencyB";
  position: absolute;
  color: white;
  font-size: 1vw;
  cursor: pointer;
  left: 18%;
  font-family: 'AgencyR', sans-serif;
  font-weight: 500;
  width: 5.3vw;
}
.footerMenuExtraMore{
  font-family: "AgencyB";
  position: absolute;
  color: white;
  font-size: 1vw;
  cursor: pointer;
  left: 46%;
  font-family: 'AgencyR', sans-serif;
  font-weight: 500;
  width: 3.6vw;
}
.arrowF {
  border: solid black;
  border-width: 0 2px 2px 0;
  display: inline-block;
  padding: 0.3vw;
  position: relative;
  top: -0.4vw;
}
.down {
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
}
.underlineFooter{
  border-bottom: 1px solid white;
}
.GamesFooter{
  left: 18%;
}
.PassFooter{
  left: 26.2%;
}
.AcademyFooter{
  left: 31.6%;
}
.TokenFooter{
  left: 39.6%;
}
.MoreFooter{
  left: 46%;
}


.CursedMansionFooter{
  top: 9vw;
}
.CyberSkiesFooter{
  top: 10.3vw;
}
.QuantumBreachFooter{
  top: 11.5vw;
}
.ChimbaRacingFooter{
  color: rgb(88, 88, 88);
  top: 14vw;
  cursor: default !important;
}
.CosmicLabsFooter{
  top: 12.75vw;
}

.AboutFooter{
  top: 9vw;
}
.FoundationFooter{
  top: 10.3vw;
}
.LabsFooter{
  top: 11.5vw;
}


.footerLine1{
  position: absolute;
  top: 8.7vw;
  left: 25.2%;
  width: 0.13vw;
}
.footerLine2{
  position: absolute;
  top: 8.7vw;
  left: 30%;
  width: 0.13vw;
}
.footerLine3{
  position: absolute;
  top: 8.7vw;
  left: 38%;
  width: 0.13vw;
}
.footerLine4{
  position: absolute;
  top: 8.7vw;
  left: 44.5%;
  width: 0.13vw;
}
.footerLine5{
  position: absolute;
  top: 8.7vw;
  left: 51%;
  width: 0.13vw;
}

.footerArrow{
  position: absolute;
  top: 8.8vw;
  left: 53.4%;
  width: 1.05vw;
}
.contactFooter{
  position: absolute;
  color: white;
  font-family: 'Thuner';
  font-size: 1.5vw;
  top: 8.1vw;
  left: 56%;
}


.line2Footer{
  position: absolute;
  width: 24vw;
  bottom: 1vw;
  left: 53.5%;
}
.line3Footer{
  position: absolute;
  width: 3vw;
  bottom: 0;
  right: 3%;
}

.footerIcon{
  position: absolute;
  width: 2.25vw;
  bottom: 0;
  cursor: pointer;
}
.igFooter{
  right: 7%;
}
.tiktokFooter{
  right: 9.5%;
}
.ytFooter{
  right: 12%;
}
.discordFooter{
  right: 14.5%;
}
.xFooter{
  right: 17%;
}

