.tokenBackground3{
  position: absolute;
  top: 108.6vw;
  width: 100%;
}
.greyLogoToken{
  position: absolute;
  top: 108.6vw;
  left: 3.8%;
  width: 46vw;
}
.line7Token{
  position: absolute;
  top: 108.6vw;
  left: 50.3%;
  width: 0.60vw;
}
.line8Token{
  position: absolute;
  top: 152vw;
  left: 50.5%;
  width: 15vw;
}
.eco1{
  position: absolute;
  top: 123.5vw;
  left: 0%;
  width: 25.2vw;
}
.eco2{
  position: absolute;
  top: 123.5vw;
  left: 25.4%;
  width: 25.4vw;
}
.eco3{
  position: absolute;
  top: 123.5vw;
  left: 50.6%;
  width: 25.4vw;
}
.eco4{
  position: absolute;
  top: 123.5vw;
  right: 0%;
  width: 24.4vw;
}

.eco5{
  position: absolute;
  top: 138vw;
  left: 0%;
  width: 25.2vw;
}
.eco6{
  position: absolute;
  top: 138vw;
  left: 25.4%;
  width: 32vw;
  height: 14.2vw;
}
.eco7{
  position: absolute;
  top: 138vw;
  left: 57.7%;
  width: 20vw;
  height: 14.2vw;
}
.eco8{
  position: absolute;
  position: absolute;
  top: 138vw;
  right: 0%;
  width: 22vw;
  height: 14.2vw;
}
.chimbaTokenWhite{
  position: absolute;
  top: 127vw;
  left: 40%;
  z-index: 5;
  width: 20vw;
}
.chimbaTokenWhiteBg{
  position: absolute;
  top: 126vw;
  left: 40%;
  z-index: 4;
  width: 20vw;
}
.ecosystemTitle{
  position: absolute;
  top: 154.5vw;
  right: 12%;
  width: 20vw;
}
.ecosystemPara{
  position: absolute;
  top: 157vw;
  right: 12%;
  text-align: right;
  font-family:'AgencyR';
  font-size: 1vw;
  font-weight: 100;
}

@media screen and (max-width: 1410px) {
  .line7Token{
    left: 50.4%;
  }
}
@media screen and (max-width: 1160px) {
  .line7Token{
    left: 50.5%;
  }
}
@media screen and (max-width: 1000px) {
  .line7Token{
    left: 50.6%;
  }
}
@media screen and (max-width: 850px) {
  .line7Token{
    left: 50.7%;
  }
}
@media screen and (max-width: 750px) {
  .line7Token{
    left: 50.8%;
  }
}
@media screen and (max-width: 700px) {
  .line7Token{
    left: 50.9%;
  }
}

@media screen and (max-width: 535px) {
  .line7Token{
    left: 49.9%;
  }
}