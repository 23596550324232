.bgShips{
    position: absolute;
    top: 164vw;
    width: 100%;
}
.line7Ships{
    position: absolute;
  top: 166vw;
  left: 50%;
  transform: translateX(-50%);
  width: 100%;
  height: auto;
  z-index: 6;
}
.shipsHeader{
    position: absolute;
    top: 173.4vw;
    left: 5%;
    width: 10vw;
    z-index: 6;
}
.line8Ships{
    position: absolute;
    top: 177vw;
    left: 6%;
    width: 5.7vw;
    z-index: 6;
}
.shipVideo{
    position: absolute;
    top: 166.5vw;
    left: 12.5%;
    width: 44.7vw;
    z-index: 5;
}
.diagramShips{
    position: absolute;
    top: 180vw;
    right: 5.2%;
    width: 35vw;
}
.shipsParaDivSkies{
    z-index: 6;
    position: absolute;
    top: 205vw;
    right: 5%;
    color: rgba(255, 255, 255, 0.700);
    font-family: 'AgencyR';
    font-size: 1.25vw;
    font-weight: 100;
}
.shipsTitleSkies{
    font-family: 'Thuner';
    font-size: 2vw;
    color: white;
}
.mainHeaderSKIESShips{
    font-family:'Thuner';
    font-size: 2.4vw;
    position: absolute;
    top: 170.4vw;
    left: 5.5%;
    z-index: 45;
    color: white;
  }
  .group-containerSKIESShips {
    position: absolute;
    top: 172.8vw;
    left: 5.5%;
    z-index: 45;
  }
  .rectangle-1SKIESShips{
    top: 3.4vw;
    width: 9.3vw;
    height: 0.1vw;
    background: white;
  }
  .rectangle-2SKIESShips {
    top: 3.4vw;
    width: 3vw;
    height: 0.1vw;
    background-color: #E7A302; /* You can use background-color for fill */
  }