/* MainInfo.css */
@font-face {
  font-family: "Thuner"; /* Choose a unique name for your font */
  src: url("../assets/fonts/Thuner.ttf") format("truetype"); /* Adjust the path accordingly */
}
@font-face {
  font-family: "AgencyR"; /* Choose a unique name for your font */
  src: url("../assets/fonts/AgencyR.ttf") format("truetype"); /* Adjust the path accordingly */
}
@font-face {
  font-family: "AgencyB"; /* Choose a unique name for your font */
  src: url("../assets/fonts/AgencyB.ttf") format("truetype"); /* Adjust the path accordingly */
}
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800;900&family=Roboto+Condensed:ital,wght@0,100..900;1,100..900&display=swap");

body {
  overflow: visible; /* Set the default overflow */
}

body.overflow-hidden {
  overflow: hidden; /* Set the overflow to hidden when the menu is open */
}

.sticky-nav-container {
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 102;
}
.sticky-nav-content {
  position: relative;
  width: 100%;
}
.transparentBackground {
  position: absolute;
  width: 100%;
  height: 5vw;
  background-color: rgba(0, 0, 0, 0.45);
  backdrop-filter: blur(3px);
  z-index: 98;
}
.transparentBackgroundHome {
  position: absolute;
  width: 100%;
  background-color: rgba(0, 0, 0, 0);
  z-index: 98;
}
.NavLine {
  position: absolute;
  top: 0%;
  left: 2%;
  width: 3vw;
  z-index: 99;
}

.GamesNav {
  position: absolute;
  margin-top: 1.7%;
  left: 6%;
  font-size: 1.2vw;
  font-family: "AgencyB";
  width: 5vw;
  color: black;
  cursor: pointer;
  transition: all 0.1s ease-in-out;
  z-index: 99;
}

.PassNav {
  position: absolute;
  margin-top: 1.7%;
  left: calc(6% + 16.4vw); /* Adjust the 10vw based on your design */
  font-size: 1.2vw;
  font-family: "AgencyB";
  color: black;
  cursor: pointer;
  transition: all 0.1s ease-in-out;
  z-index: 99;
}

.AcademyNav {
  position: absolute;
  margin-top: 1.7%;
  left: calc(6% + 19.4vw); /* Adjust the 20vw based on your design */
  font-size: 1.2vw;
  font-family: "AgencyB";
  color: black;
  cursor: pointer;
  transition: all 0.1s ease-in-out;
  z-index: 99;
}

.TokenNav {
  position: absolute;
  margin-top: 1.7%;
  left: calc(6% + 24.5vw); /* Adjust the 30vw based on your design */
  font-size: 1.2vw;
  font-family: "AgencyB";
  color: black;
  cursor: pointer;
  transition: all 0.1s ease-in-out;
  z-index: 99;
}

.MoreNav {
  position: absolute;
  margin-top: 1.7%;
  left: calc(6% + 0.5vw); /* Adjust the 40vw based on your design */
  font-size: 1.2vw;
  font-family: "AgencyB";
  color: black;
  cursor: pointer;
  width: 5vw;
  transition: all 0.1s ease-in-out;
  z-index: 99 !important;
}

.PassNav:hover,
.AcademyNav:hover,
.TokenNav:hover {
  transform: scale(0.9);
}

.split1 {
  position: absolute;
  margin-top: 1.9%;
  left: 22%;
  width: 0.1vw;
  z-index: 99;
}
.split2 {
  position: absolute;
  margin-top: 1.9%;
  left: calc(11% + 14vw);
  width: 0.1vw;
  z-index: 99;
}
.split3 {
  position: absolute;
  margin-top: 1.9%;
  left: calc(12.25% + 17.5vw);
  width: 0.1vw;
  z-index: 99;
}
.split4 {
  position: absolute;
  margin-top: 1.9%;
  left: calc(12.25% + 21.5vw);
  width: 0.1vw;
  z-index: 99;
}
.splitChangeColor {
  filter: invert(100%);
}

.arrowWhite {
  border: solid white;
  border-width: 0 2px 2px 0;
  display: inline-block;
  padding: 0.16vw;
  position: relative;
  top: -0.4vw;
}
.arrowBlack {
  border: solid black;
  border-width: 0 2px 2px 0;
  display: inline-block;
  padding: 0.16vw;
  position: relative;
  top: -0.4vw;
}
.down {
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
}

/* Styles for the dropdown container */
.dropdown {
  position: absolute;
  margin-top: 1.7%;
  left: 16.5%;
  font-size: 0.9vw;
  color: #fcb802;
  cursor: pointer;
  transition: all 0.1s ease-in-out;
  background-color: none;
  pointer-events: auto;
  z-index: 99;
}

.dropdownmore {
  position: absolute;
  margin-top: 1.7%;
  left: calc(6% + 28vw);
  font-size: 0.9vw;
  color: #fcb802;
  cursor: pointer;
  transition: all 0.1s ease-in-out;
  background-color: none;
  pointer-events: auto;
  z-index: 99;
}

/* Styles for the dropdown content */
.dropdown-content {
  display: none;
  position: absolute;
  top: 100%;
  left: 0;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
  z-index: 1;
  background-color: transparent;
  width: 4.3vw;
  font-size: 0.6vw;
  text-align: center;
  font-family: "AgencyB";
  margin-top: 1.4vw; /* Adjust as needed */
  opacity: 0; /* Start with opacity set to 0 */
  transition: opacity 0.3s ease-in-out;
}

/* Show the dropdown content when the dropdown is hovered */
.dropdown:hover .dropdown-content,
.dropdownmore:hover .dropdown-content {
  display: block;
  opacity: 1; /* Set opacity to 1 on hover */
  pointer-events: auto; /* Enable pointer events on the dropdown content */
}

/* Other styles remain unchanged */

.dropdown-item {
  display: block;
  color: white;
  text-transform: uppercase;
  background-color: black;
  padding: 5px;
  width: 100%;
  border-bottom: grey 1px solid;
}

.dropdown-item:hover {
  background-color: #fcb802;
}

.selectedDrop {
  background-color: #fcb802;
}

.academy-route {
  color: white; /* Set the font color to black for the 'academy' route */
}
.selected {
  color: #fcb802;
}

.Burger {
  position: absolute;
  margin-top: 1.5rem;
  right: 5%;
  width: 3rem;
  transition: all 0.1s ease-in-out;
  cursor: pointer;
  z-index: 9999;
}
.Burger:hover {
  transform: scale(0.9);
}
.fullscreen-menu {
  position: absolute;
  z-index: 5;
  width: 100%;
  height: 100%;
  background: rgba(
    255,
    255,
    255,
    0.8
  ); /* Adjust the alpha value for transparency */
  backdrop-filter: blur(3px);
  padding: 20px;
  z-index: 999;
}

.menuItems {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: top;
  align-items: left;
  padding: 15px;
  padding-top: 10px;
  margin: 0;
  list-style: none;
  overflow: hidden;
  color: black;
  text-transform: uppercase;
  font-family: "AgencyR", sans-serif;
}

.menuItems li {
  text-align: left;
  padding: 10px;
  border-bottom-width: 1px; /* Adjust padding as needed */
  border-bottom: 1px solid;
  cursor: pointer;
  font-size: 2.25vh;
}
.greyed{
  color: rgb(160, 160, 160) !important;
  cursor: default !important;
}

.menu-heading {
  font-weight: bold;
  font-size: 2.75vh !important; /* Adjust font size as needed */
  font-family: "AgencyB", sans-serif;
  cursor: auto !important;
}

.logoNav {
  position: absolute;
  width: 8vw;
  left: 7%;
  top: 1.5vw;
  cursor: pointer;
  transition: all ease-in 0.2s;
  z-index: 98;
}

.logoNav:hover{
  transform: scale(0.95);
}
.logoNavMobile{
  width: 200px;
  padding: 10px;
  padding-left: 20px;
  cursor: pointer;
}