@font-face {
  font-family: 'Thuner'; /* Choose a unique name for your font */
  src: url('../../../assets/fonts/Thuner.ttf') format('truetype'); /* Adjust the path accordingly */
}
@font-face {
  font-family: 'AgencyR'; /* Choose a unique name for your font */
  src: url('../../../assets/fonts/AgencyR.ttf') format('truetype'); /* Adjust the path accordingly */
}
@font-face {
  font-family: 'AgencyB'; /* Choose a unique name for your font */
  src: url('../../../assets/fonts/AgencyB.ttf') format('truetype'); /* Adjust the path accordingly */
}
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800;900&family=Roboto+Condensed:ital,wght@0,100..900;1,100..900&display=swap');

body, html {
  height: 100%;
  margin: 0;
  padding: 0;
}

.academy-container {
  position: relative;
  background-image: url('../../../assets/academy/background.png');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center top; 
  height: 111vw;
  margin: 0;
  padding: 0;
}
.line1 {
  position: absolute;
  top: 0vw;
  left: 42%;
  transform: translateX(-50%);
  width: 35vw; 
  height: auto; 
}
.line2 {
  position: absolute;
  top: 11vw;
  left: 10.5%;
  transform: translateX(-50%);
  width: 5.4vw; 
  height: auto; 
}
.line3 {
  position: absolute;
  top: 47.3%;
  right: 3%;
  width: 25vw; 
  height: auto; 
}
.scrollToNav
{
  position: absolute;
  top: 47.3%;
  right: 29%;
  width: 5vw; 
  height: auto; 
}
.mouse
{
  position: absolute;
  top: 47%;
  right: 34.5%;
  width: 1vw; 
  height: auto; 
}
.academyHeader {
  position: absolute;
  top: 8vw;
  left: 14%;
  transform: translateX(-50%);
  width: 15vw; 
  height: auto; 
}
.p1Header
{
  font-family: "Thuner";
  color: white;
  position: absolute;
  top: 38vw;
  left: 21.4%;
  letter-spacing: 1px;
  transform: translateX(-50%);
  font-size: 1.5vw;
  background: url('../../../assets/academy/shadowTitle.png'),url('../../../assets/academy/shadowTitle.png');
  background-size: 100% 100%;
  background-repeat: no-repeat;
  padding: 25px;
  width: 20vw;
  text-align: left;
  white-space: nowrap;
  z-index: 6;
}
.p1Para
{
  font-family: "Roboto Condensed", sans-serif;
  color: white;
  position: absolute;
  top: 41vw;
  left: 34%;
  transform: translateX(-50%);
  font-size: 1.25vw;
  background: url('../../../assets/academy/shadowText.png'),url('../../../assets/academy/shadowText.png');
  background-size: 100% 100%;
  background-repeat: no-repeat;
  padding: 25px;
  width: 45vw;
  text-align: left;
  font-weight: 200;
  z-index: 5;
}

/* PAGE 2 STARTS HERE */

.line4 {
  position: absolute;
  top: 55.25vw;
  left: 13.2%;
  transform: translateX(-50%);
  width: 0.6vw; 
  height: auto; 
}
.line5 {
  position: absolute;
  bottom: 0vw;
  left: 50%;
  transform: translateX(-50%);
  width: 11vw; 
  height: auto; 
}
.elearning
{
  position: absolute;
  top: 65vw;
  left: 16%;
  transform: translateX(-50%);
  width: 19vw; 
  height: auto; 
  z-index: 5;
}
.p2Para
{
  font-family: "Roboto Condensed", sans-serif;
  color: white;
  position: absolute;
  top: 68vw;
  left: 21.5%;
  transform: translateX(-50%);
  font-size: 1vw;
  background: url('../../../assets/academy/textBox.png');
  background-size: 100% 100%;
  background-repeat: no-repeat;
  padding: 3.5vw;
  width: 31vw;
  text-align: left;
  font-weight: 200;
  z-index: 5;
}
.chimbaLogoAcademy
{
  position: absolute;
  bottom: 0vw;
  left: 22%;
  transform: translateX(-50%);
  width: 37vw; 
  height: auto; 
  z-index: 2;
}

.graduate
{
  position: absolute;
  top: 63vw;
  left: 57%;
  transform: translateX(-50%);
  width: 8vw;
  cursor: pointer;
}
.bell
{
  position: absolute;
  top: 66.5vw;
  left: 57%;
  transform: translateX(-50%);
  width: 8vw;
  cursor: pointer;
}
.work
{
  position: absolute;
  top: 70vw;
  left: 57%;
  transform: translateX(-50%);
  width: 8vw;
  cursor: pointer;
}
.group
{
  position: absolute;
  top: 73.5vw;
  left: 57%;
  transform: translateX(-50%);
  width: 8vw;
  cursor: pointer;
}
.folder
{
  position: absolute;
  top: 77vw;
  left: 57%;
  transform: translateX(-50%);
  width: 8vw;
  cursor: pointer;
}
.selectedGroup
{
  width: 11vw;
  left: 55.5%;
}
.p2Image
{
  position: absolute;
  top: 54.5vw;
  right: 3%;
  width: 39vw;
  z-index: 5;
}
.lineBottom
{
  position: absolute;
  top: 95.1vw;
  right: 3.02%;
  width: 10vw;
  z-index: 4;
}
.p2ParaBottom
{
  font-family: "Roboto Condensed", sans-serif;
  color: white;
  position: absolute;
  top: 98.3vw;
  right: 14%;
  font-size: 0.8vw;
  text-align: left;
  font-weight: 200;
  z-index: 5;
  width: 25vw;
  text-align: right;
}

@media screen and (max-width: 1000px) {
  .academy-container {
    background-position: center top; /* Adjust for smaller screens */
  }
}

.storyHeaderAcademy{
  font-family:'Thuner';
  font-size: 2.4vw;
  position: absolute;
  top: 61.5vw;
  left: 8.2%;
  z-index: 45;
  color: white;
}
.group-containerAcademy{
  position: absolute;
  top: 64vw;
  left: 8.2%;
  z-index: 45;
}
.rectangle {
  position: absolute;
}
.rectangle-1Acad {
  top: 3.4vw;
  width: 19.1vw;
  height: 0.1vw;
  background: white;
}
.rectangle-2Acad {
  top: 3.4vw;
  width: 4vw;
  height: 0.1vw;
  background-color: #E7A302; /* You can use background-color for fill */
}
