.worldBG{
    position: absolute;
    z-index: 6;
    top: 222.8vw;
    width: 100%;
}
.quantumLogo5{
    position: absolute;
    top: 228vw;
    right: 2vw;
    width: 12vw;
    z-index: 7;
}
.line10Quantum{
    position: absolute;
    top: 222.8vw;
  left: 50%;
  transform: translateX(-50%);
  width: 100%;
  height: auto; 
  z-index: 7;
}
.worldHeader{
    position: absolute;
    z-index: 7;
    top: 241vw;
    left: 5.7%;
    width: 12.4vw;
}
.line9Quantum{
    position: absolute;
    z-index: 7;
    top: 245vw;
    left: 7.3%;
    width: 5.2vw;
}
.line8Quantum{
    position: absolute;
    z-index: 7;
    top: 272.1vw;
    right: 9%;
    width: 40vw;
}
#icon1World{
    position: absolute;
    z-index: 7;
    top: 268.8vw;
    right: 42%;
    cursor: pointer;
    transition: all ease-in-out 0.2s;
    border-radius: 99px;
    width: 5vw;
}
#icon2World{
    position: absolute;
    z-index: 7;
    top: 268.8vw;
    right: 34%;
    cursor: pointer;
    transition: all ease-in-out 0.2s;
    border-radius: 99px;
    width: 5vw;
}
#icon3World{
    position: absolute;
    z-index: 7;
    top: 268.8vw;
    right: 26%;
    cursor: pointer;
    transition: all ease-in-out 0.2s;
    border-radius: 99px;
    width: 5vw;
}
#icon4World{
    position: absolute;
    z-index: 7;
    top: 268.8vw;
    right: 18%;
    cursor: pointer;
    transition: all ease-in-out 0.2s;
    border-radius: 99px;
    width: 5vw;
}
.iconWorldSelected{
    border: 2px solid #FCB802;
}
#icon1World:hover,#icon2World:hover,#icon3World:hover,#icon4World:hover{
    transform: scale(0.95);
}

.worldParaDivQuantum{
    z-index: 7;
    position: absolute;
    top: 262vw;
    left: 10%;
    color: rgba(255, 255, 255, 0.700);
    font-family: 'AgencyR';
    font-size: 1.25vw;
    font-weight: 100;
    padding: 3vw;
    background: url('../../../../assets/waifus/shadowText.png');
    background-size: 88% 80%;
    background-position: center;
    background-repeat: no-repeat;
    width: 30vw;
}
.worldTitleQuantum{
    font-family: 'Thuner';
    font-size: 2vw;
    color: white;
}
.textArrowWorld{
    position: absolute;
    z-index: 7;
    top: 269vw;
    left: 6.6%;
    width: 2vw;
}
.mainHeaderQBWorld{
    font-family:'Thuner';
    font-size: 2.4vw;
    position: absolute;
    top: 235.5vw;
    left: 6.5%;
    z-index: 45;
    color: white;
  }
  .group-containerQBWorld{
    position: absolute;
    top: 238vw;
    left: 6.6%;
    z-index: 45;
  }
  .rectangle-1QBWorld{
    top: 3.4vw;
    width: 11.4vw;
    height: 0.1vw;
    background: white;
  }
  .rectangle-2QBWorld {
    top: 3.4vw;
    width: 3vw;
    height: 0.1vw;
    background-color: #E7A302; /* You can use background-color for fill */
  }