@font-face {
  font-family: 'Thuner'; /* Choose a unique name for your font */
  src: url('../../../../assets/fonts/Thuner.ttf') format('truetype'); /* Adjust the path accordingly */
}
@font-face {
  font-family: 'AgencyR'; /* Choose a unique name for your font */
  src: url('../../../../assets/fonts/AgencyR.ttf') format('truetype'); /* Adjust the path accordingly */
}
@font-face {
  font-family: 'AgencyB'; /* Choose a unique name for your font */
  src: url('../../../../assets/fonts/AgencyB.ttf') format('truetype'); /* Adjust the path accordingly */
}
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800;900&family=Roboto+Condensed:ital,wght@0,100..900;1,100..900&display=swap');


.cursedBackground{
    position: absolute;
    top: 0vw;
    width: 100%;
}
.line1Cursed {
  position: absolute;
  top: 0vw;
  left: 50%;
  transform: translateX(-50%);
  width: 100%;
  height: auto; 
}
.cursedMansionHeader{
    position: absolute;
    top: 7vw;
    left: 6.5%;
    width: 30vw;
}
.playButtonCursed{
    position: absolute;
    left: 47.5%;
    top: 24vw;
    width: 6vw;
    cursor: pointer;
    transition: all ease-in-out 0.2s;
}
.playButtonCursed:hover{
    transform: scale(0.95);
}

.cursedSeparator{
  position: absolute;
  width: 100%;
  top: 40vw;
}
.mainHeaderCM{
  font-family:'Thuner';
  font-size: 2.4vw;
  position: absolute;
  top: 4.6vw;
  left: 3.5%;
  z-index: 45;
  color: white;
}
.group-containerCMMain {
  position: absolute;
  top: 7vw;
  left: 3.6%;
  z-index: 45;
}
.rectangle {
  position: absolute;
}
.rectangle-1Main {
  top: 3.4vw;
  width: 27.2vw;
  height: 0.1vw;
  background: white;
}
.rectangle-2Main {
  top: 3.4vw;
  width: 8vw;
  height: 0.1vw;
  background-color: #E7A302;
}
