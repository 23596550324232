.bgRewards{
    position: absolute;
    top: 218.5vw;
    width: 100%;
}
.line9Rewards{
    position: absolute;
  top: 222.3vw;
  left: 50%;
  transform: translateX(-50%);
  width: 100%;
  height: auto;
  z-index: 6;
}
.rewardsHeader{
    position: absolute;
    top: 231vw;
    left: 5%;
    width: 16.5vw;
}
.line10Rewards{
    position: absolute;
    top: 235vw;
    width: 6vw;
    left: 6%;
}
.rewardsHeaderSkies{
    position: absolute;
    top: 63.3vw;
    left: 6.7%;
    width: 14.5vw;
}
.rewardsParaDivSkies{
    z-index: 6;
    position: absolute;
    top: 257vw;
    left: 12%;
    color: rgba(255, 255, 255, 0.700);
    font-family: 'AgencyR';
    font-size: 1.25vw;
    font-weight: 100;
}
.rewardsTitleSkies{
    font-family: 'Thuner';
    font-size: 2vw;
    color: white;
}
.rewardVideo{
    position: absolute;
    top: 225vw;
    left: 40%;
    width: 44vw;
    z-index: 5;
}
.mainHeaderSKIESRewards{
    font-family:'Thuner';
    font-size: 2.4vw;
    position: absolute;
    top: 229vw;
    left: 5.5%;
    z-index: 45;
    color: white;
  }
  .group-containerSKIESRewards {
    position: absolute;
    top: 231.5vw;
    left: 5.6%;
    z-index: 45;
  }
  .rectangle-1SKIESRewards{
    top: 3.4vw;
    width: 15.7vw;
    height: 0.1vw;
    background: white;
  }
  .rectangle-2SKIESRewards {
    top: 3.4vw;
    width: 4vw;
    height: 0.1vw;
    background-color: #E7A302; /* You can use background-color for fill */
  }