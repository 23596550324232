.background2Cursed{
    position: absolute;
    top: 63vw;
    width: 100%;
}
.line2Cursed{
    position: absolute;
  top: 63vw;
  left: 50%;
  transform: translateX(-50%);
  width: 100%;
  height: auto; 
}

.historyParaDivCursed{
    z-index: 6;
    position: absolute;
    top: 83.2vw;
    left: 11%;
    color: rgb(182, 182, 182);
    font-family: 'AgencyR';
    font-size: 1.25vw;
    font-weight: 100;
}
.historyTitleCursed{
    font-family: 'Thuner';
    font-size: 2vw;
}
.separator2{
    position: absolute;
    top: 116vw;
    width: 100%;
    z-index: 4;
}

.storyHeaderCM{
    font-family:'Thuner';
    font-size: 2.4vw;
    position: absolute;
    top: 68vw;
    left: 7.5%;
    z-index: 45;
    color: white;
}
.group-containerCM {
    position: absolute;
    top: 70.5vw;
    left: 7.5%;
    z-index: 45;
  }
  .rectangle {
    position: absolute;
  }
  .rectangle-1 {
    top: 3.4vw;
    width: 10.4vw;
    height: 0.1vw;
    background: white;
  }
  .rectangle-2 {
    top: 3.4vw;
    width: 4vw;
    height: 0.1vw;
    background-color: #E7A302; /* You can use background-color for fill */
  }