.blackBG2{
    z-index: 5;
    position: absolute;
    top: 110.5vw;
    width: 100vw;
}
.quantumLogo3{
    position: absolute;
    top: 112vw;
    right: 2vw;
    width: 12vw;
    z-index: 7;
}
.characterLine1{
    z-index: 7;
    position: absolute;
  top: 110.5vw;
  left: 50%;
  transform: translateX(-50%);
  width: 100%;
  height: auto; 
}

.charactersHeaderQuantum{
    z-index: 7;
    position: absolute;
    top: 123.5vw;
    left: 8.9%;
    width: 20vw;
}
.quantamCharacterSelected{
    z-index: 6;
    position: absolute;
    top: 110.5vw;
    left: 8%;
    width: 92vw;
}

.characterParaDivQuantum{
    z-index: 7;
    position: absolute;
    top: 149.5vw;
    left: 12%;
    color: rgba(255, 255, 255, 0.700);
    font-family: 'AgencyR';
    font-size: 1.25vw;
    font-weight: 100;
    width: 28.5vw;
}
.characterTitleQuantum{
    font-family: 'Thuner';
    font-size: 2vw;
    color: white;
}
.iconLineQuantum{
    z-index: 7;
    position: absolute;
    top: 162.1vw;
    right: 2%;
    width: 56.4vw;
}
#icon1{
    left: 50%;
}
#icon2{
    left: 58%;
}
#icon3{
    left: 66%;
}
#icon4{
    left: 74%;
}
#icon5{
    left: 82%;
}
.iconQuantum{
    z-index: 7;
    position: absolute;
    top: 157vw;
    width: 4.5vw;
    cursor: pointer;
    border-radius: 99px;
    transition: all ease-in-out 0.2s;
}
.iconQuantum:hover{
    transform: scale(0.98);
}
.iconQuantumSelected{
    border: 2px solid #FCB802;
}

.mainHeaderQBChar{
    font-family:'Thuner';
    font-size: 2.4vw;
    position: absolute;
    top: 116.5vw;
    left: 6.5%;
    z-index: 45;
    color: white;
  }
  .group-containerQBChar{
    position: absolute;
    top: 119vw;
    left: 6.6%;
    z-index: 45;
  }
  .rectangle-1QBChar{
    top: 3.4vw;
    width: 20.7vw;
    height: 0.1vw;
    background: white;
  }
  .rectangle-2QBChar {
    top: 3.4vw;
    width: 5vw;
    height: 0.1vw;
    background-color: #E7A302; /* You can use background-color for fill */
  }