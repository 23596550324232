@font-face {
  font-family: 'Thuner'; /* Choose a unique name for your font */
  src: url('../../../assets/fonts/Thuner.ttf') format('truetype'); /* Adjust the path accordingly */
}

@font-face {
  font-family: 'AgencyR'; /* Choose a unique name for your font */
  src: url('../../../assets/fonts/AgencyR.ttf') format('truetype'); /* Adjust the path accordingly */
}

@font-face {
  font-family: 'AgencyB'; /* Choose a unique name for your font */
  src: url('../../../assets/fonts/AgencyB.ttf') format('truetype'); /* Adjust the path accordingly */
}

@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800;900&family=Roboto+Condensed:ital,wght@0,100..900;1,100..900&display=swap');

body {
  background: white;
  overflow-x: hidden !important;
}

.aboutHeader {
  position: absolute;
  top: 10vw;
  left: 11.5%;
  transform: translateX(-50%);
  width: 10vw;
  height: auto;
}

.line1A {
  position: absolute;
  top: 5vw;
  left: 35%;
  transform: translateX(-50%);
  width: 21vw;
  height: auto;
}

.line2A {
  position: absolute;
  top: 14vw;
  left: 10%;
  transform: translateX(-50%);
  width: 5vw;
  height: auto;
  z-index: 6;
}

.line3A {
  position: absolute;
  top: 46vw;
  right: 3%;
  width: 25vw;
  z-index: 45;
  height: auto;
}

.scrollToNavA {
  position: absolute;
  top: 46vw;
  right: 29%;
  width: 5vw;
  height: auto;
}

.mouseA {
  position: absolute;
  top: 45.7vw;
  right: 34.5%;
  width: 1vw;
  height: auto;
}
.aboutLogo{
  position: absolute;
  width: 28vw;
  right: 0vw;
  top: 1.5vw;
  clip-path: inset(8% 0% 0% 0%);
}

.chimbaGames {
  position: absolute;
  top: 4vw;
  right: 10%;
  width: 25vw;
  height: auto;
  z-index: 4;
}

.chimbaBlock {
  position: absolute;
  top: 25vw;
  width: 100%;
  right: 0%;
  z-index: 5;
}

.chimbaHeader {
  position: absolute;
  top: 25vw;
  width: 18.5vw;
  left: 17%;
  z-index: 6;
}

.learnMore {
  position: absolute;
  top: 40vw;
  width: 14vw;
  left: 26%;
  z-index: 6;
  cursor: pointer;
  transition: all 0.25s ease-in-out;
}

.learnMore:hover {
  transform: scale(0.98);
}

.aboutPara {
  font-family: "Roboto Condensed", sans-serif;
  position: absolute;
  top: 32vw;
  left: 18%;
  z-index: 6;
  color: black;
  font-size: 1vw;
  line-height: 1.5;
}

.line4About{
  position: absolute;
  top: 55.4vw;
  left: 12.25%;
  width: 0.5vw;
}
.line5About{
  position: absolute;
  top: 70vw;
  left: 7.7%;
  width: 6vw;
  z-index: 5;
}
.line6About{
  position: absolute;
  top: 48vw;
  right: 3%;
  width: 9vw;
  z-index: 46;
}
.firstHeaderAbout{
  position: absolute;
  top: 67vw;
  left: 7.5%; 
  width: 10vw;
}
.secondHeaderAbout{
  position: absolute;
  top: 92vw;
  right: 15%; 
  width: 10vw;
}
.firstHPAbout{
  font-family: "Roboto Condensed", sans-serif;
  position: absolute;
  top: 69vw;
  left: 15%;
  z-index: 6;
  color: black;
  font-weight: 300;
  font-size: 1vw;
  line-height: 1.5;
}
.secondHPAbout{
  font-family: "Roboto Condensed", sans-serif;
  position: absolute;
  top: 95vw;
  right: 15%;
  z-index: 6;
  color: black;
  font-weight: 300;
  text-align: right;
  font-size: 1vw;
  line-height: 1.5;
}
.goldChimba{
  position: absolute;
  width: 39vw;
  left: 45%;
  top: 57vw;
}
.zoe{
  position: absolute;
  width: 100%;
  left: 0%;
  top: 44vw;
}