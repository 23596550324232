@font-face {
  font-family: "bigmacca"; /* Choose a unique name for your font */
  src: url("../../../../assets/cyberSkies/bigmacca.ttf") format("truetype"); /* Adjust the path accordingly */
}
.bgSkies2 {
  position: absolute;
  width: 100%;
  top: 109.5vw;
  z-index: 3;
}
.line5Skies {
  position: absolute;
  top: 109.5vw;
  left: 50%;
  transform: translateX(-50%);
  width: 100%;
  height: auto;
  z-index: 6;
}
.characterHeaderSkies {
  position: absolute;
  top: 119vw;
  left: 6.5%;
  width: 19.5vw;
  z-index: 6;
}
.line6Skies {
  position: absolute;
  top: 124vw;
  left: 7.5%;
  width: 49vw;
  z-index: 6;
}
.characterSelectedBG {
  z-index: 4;
  position: absolute;
  top: 110.4vw;
  width: 49.3vw;
  opacity: 0.3;
}
.characterSelectedTN {
  z-index: 5;
  position: absolute;
  top: 115vw;
  left: 14%;
  opacity: 0.5;
  width: 72vw;
}
.characterFull {
  z-index: 5;
  position: absolute;
  top: 109.5vw;
  left: 35%;
  width: 29vw;
}
.cyberSkiesStars {
  z-index: 6;
  position: absolute;
  top: 153vw;
  left: 5.7%;
  width: 24vw;
}
.textBackgroundSkies {
  z-index: 5;
  position: absolute;
  top: 144.5vw;
  left: 0%;
  width: 47vw;
}
.characterStatsSkies {
  z-index: 5;
  position: absolute;
  top: 123vw;
  right: 17%;
  width: 17vw;
}
.selectArrowSkies {
  z-index: 5;
  position: absolute;
  right: 9%;
  width: 3vw;
}
.skiesIcons {
  z-index: 15;
  position: absolute;
  cursor: pointer;
  right: 5%;
  width: 4vw;
}
.doraIcon {
  top: 127vw;
}
.novaIcon {
  top: 132vw;
}
.drIcon {
  top: 137vw;
}
.jaxIcon {
  top: 142vw;
}
.characterName {
  z-index: 5;
  position: absolute;
  top: 144vw;
  left: 10%;
  color: white;
  font-size: 4vw;
  font-family: "bigmacca";
  letter-spacing: 0.5vw;
}

.mainHeaderSKIESChar {
  font-family: "Thuner";
  font-size: 2.4vw;
  position: absolute;
  top: 115vw;
  left: 6.1%;
  z-index: 45;
  color: white;
}
.group-containerSKIESChar {
  position: absolute;
  top: 117.5vw;
  left: 6.2%;
  z-index: 45;
}
.rectangle-1SKIESChar {
  top: 3.4vw;
  width: 20.7vw;
  height: 0.1vw;
  background: white;
}
.rectangle-2SKIESChar {
  top: 3.4vw;
  width: 5vw;
  height: 0.1vw;
  background-color: #e7a302; /* You can use background-color for fill */
}
