@font-face {
  font-family: 'Thuner';
  src: url('../../../assets/fonts/Thuner.ttf') format('truetype');
}

@font-face {
  font-family: 'AgencyR';
  src: url('../../../assets/fonts/AgencyR.ttf') format('truetype');
}

@font-face {
  font-family: 'AgencyB';
  src: url('../../../assets/fonts/AgencyB.ttf') format('truetype');
}

@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800;900&family=Roboto+Condensed:ital,wght@0,100..900;1,100..900&display=swap');

.founderBackground {
  position: absolute;
  width: 100%;
  top: 132.2vw;
}

.founderLine {
  position: absolute;
  top: 132vw;
  left: 12.15%;
  width: 0.7vw;
}

.teamHeader {
  position: absolute;
  top: 141vw;
  left: 6%;
  width: 9vw;
}

.founderChimbaLogo {
  position: absolute;
  top: 160vw;
  left: 0%;
  z-index: 99;
  width: 41vw;
}

.founderBGText {
  position: absolute;
  top: 144vw;
  left: 6%;
  width: 84vw;
}

.founderLine2 {
  position: absolute;
  top: 138.5vw;
  left: 20%;
  width: 73.8dvw;
}

.leftArrowTeam {
  position: absolute;
  right: 63.5%;
  width: 3vw;
  top: 171.5vw;
  cursor: pointer;
  transition: all ease-in-out 0.2s;
  z-index: 99;
}

.rightArrowTeam {
  position: absolute;
  right: 3.5%;
  width: 3vw;
  top: 171.5vw;
  cursor: pointer;
  transition: all ease-in-out 0.2s;
  z-index: 99;
}

.leftArrowTeam:hover, .rightArrowTeam:hover {
  transform: scale(0.95);
}

/* First Row */
.team.firstRow {
  position: absolute;
  top: 159vw;  /* Moved up */
  width: 13vw;
}

/* Second Row */
.team.secondRow {
  position: absolute;
  top: 172vw;  /* Original position */
  width: 13vw;
}

/* Separators First Row */
.separate.firstRow {
  position: absolute;
  top: 161vw;  /* Adjusted for first row */
  width: 0.55vw;
  right: 43.5%;
}

.separate2.firstRow {
  position: absolute;
  top: 161vw;  /* Adjusted for first row */
  width: 0.55vw;
  right: 26.5%;
}

/* Separators Second Row */
.separate.secondRow {
  position: absolute;
  top: 174vw;  /* Adjusted for first row */
  width: 0.55vw;
  right: 43.5%;
}

.separate2.secondRow {
  position: absolute;
  top: 174vw;  /* Adjusted for first row */
  width: 0.55vw;
  right: 26.5%;
}

/* Image positions - First Row */
.firstImage.firstRow {
  left: 41%;
}

.secondImage.firstRow {
  left: 58%;
}

.thirdImage.firstRow {
  left: 75%;
}

/* Image positions - Second Row */
.firstImage.secondRow {
  left: 41%;
}

.secondImage.secondRow {
  left: 58%;
}

.thirdImage.secondRow {
  left: 75%;
}