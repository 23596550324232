.welcomeImage{
    position: absolute;
    right: 0;
    top: 54vw;
    width: 50.5vw;
    z-index: 6;
}
.quantumLogo2{
    position: absolute;
    top: 56vw;
    right: 2vw;
    width: 12vw;
    z-index: 6;
}
.blackBG{
    z-index: 5;
    position: absolute;
    top: 54.3vw;
    width: 100vw;
}
.historyLine1{
    position: absolute;
    top: 54.3vw;
    left: 50%;
    transform: translateX(-50%);
    width: 100%;
    height: auto; 
  z-index: 6;
}
.historyLine2{
    position: absolute;
    left: 8%;
    top: 66vw;
    z-index: 6;
    width: 5.65vw;
}
.historyHeader{
    position: absolute;
    left: 6.5%;
    top: 62.8vw;
    z-index: 6;
    width: 14.5vw;
}
.historyParaDiv{
    z-index: 6;
    position: absolute;
    top: 72.5vw;
    left: 11%;
    color: rgb(182, 182, 182);
    font-family: 'AgencyR';
    font-size: 1.5vw;
    font-weight: 100;
    width: 35vw;
}
.historyTitle{
    font-family: 'Thuner';
    font-size: 2vw;
}
.historyChimbaLogo{
    top: 82vw;
    left: 3%;
    position: absolute;
    z-index: 6;
    width: 49vw;
}
.storyHeaderQB{
    font-family:'Thuner';
    font-size: 2.4vw;
    position: absolute;
    top: 59.4vw;
    left: 8.2%;
    z-index: 45;
    color: white;
}
.group-containerQB {
    position: absolute;
    top: 62vw;
    left: 8.2%;
    z-index: 45;
  }
  .rectangle {
    position: absolute;
  }
  .rectangle-1 {
    top: 3.4vw;
    width: 10.4vw;
    height: 0.1vw;
    background: white;
  }
  .rectangle-2 {
    top: 3.4vw;
    width: 4vw;
    height: 0.1vw;
    background-color: #E7A302; /* You can use background-color for fill */
  }
