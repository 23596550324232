.loader-container {
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 99;
    transform: translate();
    max-width: 100%;
}

.gate {
    position: absolute;
    width: 55.4%;
    z-index: 99;
    height: 100%;
}

.left {
    left: 0;
}

.right {
    right: 0;
}
.mobileLeft{
    width: 62.5%;
}
.mobileRight{
    width: 62.5% !important;
}

.progress-label {
    position: absolute;
    top: 90.4%;
    left: 14%;
    font-size: 13px;
    z-index: 100;
    width: 91px;
    text-align: center;
}

.loading-icon {
    position: absolute;
    width: 10px;
    top: 92%;
    left: 12.3%;
    z-index: 100;
}

.loading-bar {
    position: absolute;
    top: 94.2%;
    left: 12.3%;
    z-index: 100;
    width: 32.5vw;
    max-width: 32.5vw;
    filter: invert(100%);
    transition: all 0.2s ease-in-out;
}

.loading-bar-background {
    position: absolute;
    top: 94.2%;
    left: 12.3%;
    z-index: 100; 
    height: 1.35px; 
    background-color: #fef0d4;
  }
  
.complete .left {
    animation: openLeftGate 10s cubic-bezier(0.4, 0, 0.2, 1) forwards;
}

.complete .right {
    animation: openRightGate 10s cubic-bezier(0.4, 0, 0.2, 1) forwards;
}

.complete .progress-label {
    animation: openLeftProgress 10s cubic-bezier(0.4, 0, 0.2, 1) forwards;
}

.complete .loading-icon, .complete .loading-bar, .complete .loading-bar-background {
    animation: openLeftBarIcon 10s cubic-bezier(0.4, 0, 0.2, 1) forwards;
}
.lower-zindex {
    z-index: -1; 
  }

.mobileLabel{
    top: 80%;
    left: 12%;
}
.mobileIcon{
    top: 81.5%;
    left: 9%;
}
.mobileBackgroundBar{
    top: 84%;
    left: 9%;
}
.mobileBar{
    top: 84%;
    left: 9%;
}

.complete .mobileLabel {
    animation: openLeftProgressMobile 10s cubic-bezier(0.4, 0, 0.2, 1) forwards;
}

.complete .mobileIcon, .complete .mobileBar, .complete .mobileBackgroundBar {
    animation: openLeftBarIconMobile 10s cubic-bezier(0.4, 0, 0.2, 1) forwards;
}

@keyframes openLeftGate {
    from {
        left: 0;
    }

    to {
        left: -100%;
    }
}

@keyframes openRightGate {
    from {
        right: 0;
    }

    to {
        right: -100%;
    }
}

@keyframes openLeftProgress {
    from {
        left: 14%;
    }

    to {
        left: -100%;
    }
}

@keyframes openLeftBarIcon {
    from {
        left: 12.3%;
    }

    to {
        left: -100%;
    }
}

@keyframes openLeftProgressMobile {
    from {
        left: 12%;
    }

    to {
        left: -100%;
    }
}

@keyframes openLeftBarIconMobile {
    from {
        left: 9%;
    }

    to {
        left: -100%;
    }
}




