@font-face {
  font-family: 'Thuner'; /* Choose a unique name for your font */
  src: url('../../../assets/fonts/Thuner.ttf') format('truetype'); /* Adjust the path accordingly */
}
@font-face {
  font-family: 'AgencyR'; /* Choose a unique name for your font */
  src: url('../../../assets/fonts/AgencyR.ttf') format('truetype'); /* Adjust the path accordingly */
}
@font-face {
  font-family: 'AgencyB'; /* Choose a unique name for your font */
  src: url('../../../assets/fonts/AgencyB.ttf') format('truetype'); /* Adjust the path accordingly */
}
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800;900&family=Roboto+Condensed:ital,wght@0,100..900;1,100..900&display=swap');

body{
  width: 100%;
  margin: 0;
  padding: 0;
}
.passHeader {
  font-family:'Thuner';
  font-size: 2.4vw;
  position: absolute;
  top: 6.8vw;
  left: 7.1%;
  z-index: 45;
}
.group-containerPass {
  position: absolute;
  top: 9vw;
  left: 7.2%;
  z-index: 45;
}
.rectangle {
  position: absolute;
}
.rectangle-1Pass {
  top: 3.4vw;
  width: 21vw;
  height: 0.1vw;
  background: black;
}
.rectangle-2Pass {
  top: 3.4vw;
  width: 4vw;
  height: 0.1vw;
  background-color: #E7A302; /* You can use background-color for fill */
}
.line1Pass{
  position: absolute;
  top: 14vw;
  left: 8.2%;
  width: 6.5vw;
}
.chimbaLogoPass{
  position: absolute;
  width: 90vh;
  bottom: 0vh;
  left: 0%;
}
.playButton{
  position: absolute;
  width: 2vw;
  top: 18vw;
  left: 23.5%;
  cursor: pointer;
  transition: all 0.1s ease-in-out;
}
.playButton:hover{
  transform: scale(0.95);
}
.trailer{
	font-family:'Thuner';
  color:#939393;
	font-size: 2vw;
  position: absolute;
  top: 15.5vw;
  left: 26.5%;
  cursor: pointer;
}
.controller{
  position: absolute;
  top: 13.5vw;
  left: 38%;
  width: 8vw;
  cursor: pointer;
}
.passPara{
  position: absolute;
  font-family: "Roboto Condensed";
  color: #939393;
  font-size: 1vw;
  top: 21.5vw;
  left: 23.6%;
  width: 500px;
}
#mousePass{
  position: absolute;
  right: 34%;
  bottom: 0.8vw;
  width: 1vw;
  z-index: 99 !important;
}
#scrollToNavPass{
  position: absolute;
  right: 27.5%;
  bottom: 1.1vw;
  width: 6vw;
  z-index: 99 !important;
}
#mouseLinePass{
  position: absolute;
  right: 2%;
  bottom: 0vw;
  z-index: 99 !important;
  width: 25vw;
}

#video-container {
  position: relative;
  display: none;
  width: 92%; /* Set the width to 80% of the viewport width */
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%); /* Center the image using transform */
  margin-top: 0.5vw;
  z-index: 99;
}

video {
  width: 100%; /* Make the video fill the container */
  height: auto; /* Maintain the aspect ratio */
}

#videoPlayButton {
  position: absolute;
  z-index: 100; /* Set a higher z-index to ensure the image is above the video */
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%); /* Center the image using transform */
  width: 5vw;
  cursor: pointer;
}
#smallPlayButton{
  position: absolute;
  z-index: 100; /* Set a higher z-index to ensure the image is above the video */
  bottom: 0%;
  left: 5%;
  transform: translate(-50%, -50%); /* Center the image using transform */
  width: 2vw;
  cursor: pointer;
}
#smallPauseButton{
  position: absolute;
  z-index: 100; /* Set a higher z-index to ensure the image is above the video */
  bottom: 0%;
  left: 5%;
  transform: translate(-50%, -50%); /* Center the image using transform */
  width: 2vw;
  cursor: pointer;
}
#fullScreenVideo{
  position: absolute;
  z-index: 100; /* Set a higher z-index to ensure the image is above the video */
  bottom: 0%;
  right: 5%;
  transform: translate(-50%, -50%); /* Center the image using transform */
  width: 1.75vw;
  cursor: pointer;
}
#closeVideoButton{
  position: absolute;
  z-index: 100; /* Set a higher z-index to ensure the image is above the video */
  top: 5%;
  right: -2.6%;
  transform: translate(-50%, -50%); /* Center the image using transform */
  width: 5vw;
  cursor: pointer;
}

#video-container {
  position: absolute;
}

.progress-bar-container {
  position: absolute;
  bottom: 3%;
  left: 50%;
  width: 70%;
  height: 4px; /* Adjust the height of the progress bar */
  background-color: #fff; /* Background color of the progress bar container */
  transform: translate(-50%, -50%);
  cursor: pointer;
}

.progress-bar {
  height: 100%;
  background-color: #FCB802; /* Color of the progress bar */
}